/******************
*
* Logs events in GoogleAnalitycs
*
*
* Documentation: see `analytics.README.md`
*
*/

if(typeof FN === 'undefined'){ var FN = {}; }


FN.analytics = {
  init: function(){
    FN.analytics._setTriggers();
  },

  /**
    Array where events are configured: {
      'config': {
        'selector': '.button__buy--header.analytics--click'   --> CSS selector to match the desired HTML element; must also include an ".analytics--" class
        'waitBeforeDefault': true,                            --> determines if 'preventDefault' should be called to wait before the page unloads (and therefore interrupts data sending)
        'triggerOnlyOnce': true                               --> determines if the event handler should be removed after one execution
      },
      'event': {
        'eventCategory': 'sales',                             --> eventCategory string (analytics data)
        'eventAction': 'buy-button',                          --> eventAction string (analytics data)
        'eventLabelSuffix': 'header',                         --> eventLabelSuffix string (analytics data) - is added to an automatic label prefix string
      }
    }
  */
  events: [],


  /**
    Adds an array of configured events to the global list of tracked events
    Note: each page should add its own events with this method
  */
  addConfiguredEvents: function(eventsList){
    FN.analytics.events = FN.analytics.events.concat(eventsList);
  },


  /**
    Sends analytics data to "Google Analytics"
    via Google Tag Manager's "Data Layer" object

    Parameters: eventData {
      'eventCategory': string,
      'eventAction': string,
      'eventLabelSuffix': string
    }
  */
  sendEventToGA: function(eventData){
    var eventLabel    = FN.analytics._createEventLabelString(eventData.eventLabelSuffix);
    var eventObject   = FN.analytics._createEventObjectForGA( eventData.eventCategory,
                                                              eventData.eventAction,
                                                              eventLabel);

    // Sends the event to Google Analytics
    if(typeof dataLayer !== 'undefined'){
      dataLayer.push(eventObject);
    }
  },


  /**
    Sets up event handlers for click and submit events
    Only to elements that have CSS classes of ".analytics--click" or ".analytics--submit"
  */
  _setTriggers: function(){
    var clickNamespace = 'click.analytics';
    $(document).on(clickNamespace, '.analytics--click', function(event){
      FN.analytics._handleEvent($(this), event, clickNamespace);
    });

    var submitNamespace = 'submit.analytics';
    $(document).on(submitNamespace, '.analytics--submit', function(event){
      FN.analytics._handleEvent($(this), event, submitNamespace);
    });
  },


  /**
    Handles the events, finds the corresponding configuration and initiates data sending to analytics
  */
  _handleEvent: function(domElement, domEvent, eventNamespace){
    var eventConfiguration = false;

    // find event configuration in FN.analytics.events
    for(var i=0; i<FN.analytics.events.length; i++){
      if(domElement.is(FN.analytics.events[i].config.selector)){
        eventConfiguration = FN.analytics.events[i];
        break;
      }
    }
    if(eventConfiguration == false) return false;    // no matching event configuration found

    // send analitycs data
    FN.analytics._prepareSendingToGA({'domElement': domElement, 'domEvent': domEvent, 'eventConfiguration': eventConfiguration });

    // remove event handler, if 'triggerOnlyOnce' is configured
    if('triggerOnlyOnce' in eventConfiguration.config && eventConfiguration.config.triggerOnlyOnce === true){
      $(domElement).off(eventNamespace);
    }
  },


  /**
    Prepares sending data to "Google Analytics"
    Parameters: options = {
      'domElement': DOM Element,
      'domEvent': DOM Event,
      'eventConfiguration': event configuration from FN.analytics.events
    }
  */
  _prepareSendingToGA: function(options){
    // Links that load in the same browser window must be postponed to prevent
    // the browser from unloading the current page before analytics data has been sent
    if("waitBeforeDefault" in options.eventConfiguration.config && options.eventConfiguration.config.waitBeforeDefault === true){
      // Prevent the browser from unloading the current page
      options.domEvent.preventDefault();

      // Create a timeout to call `continueLoading` after one second.
      setTimeout(continueLoading, 1000);

      // Keeps track of whether or not the data has been sent alreay. This prevents the action from being exectude twice in cases where `hitCallback` fires normally.
      var finishedSending = false;

      function continueLoading() {
        if (!finishedSending) {
          finishedSending = true;
          // NOTE: currently, waiting is only implemented for "click" events
          if(options.domEvent.type == 'click'){
            document.location = $(options.domElement).attr('href');
          }
        }
      }

      options.eventCallback = continueLoading;
    }

    FN.analytics.sendEventToGA({ 'eventCategory': options.eventConfiguration.event.eventCategory,
                                 'eventAction': options.eventConfiguration.event.eventAction,
                                 'eventLabelSuffix': options.eventConfiguration.event.eventLabelSuffix });
  },


  /**
    Creates a JS object with suitable format/keys for sending to Google Analytics / Google Tag Manager
  */
  _createEventObjectForGA: function(category, action, label){
    return {'event': 'customEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label};
  },


  /**
    Create a proper event label string
  */
  _createEventLabelString: function(eventLabelSuffix) {
    var eventLabel  = FN.config.analytics.siteID;
    eventLabel += (FN.config.analytics.addPlatformToEventLabel ? ('/' + FN.helpers.platform()) : '');
    eventLabel += '/' + eventLabelSuffix;

    return eventLabel;
  }

};




$(document).ready(function(){
  FN.analytics.init();
});
