/******************
* Sharing Sidebar
*******************/
if(typeof FN == 'undefined') FN = {};


FN.sharingBar = {
  config: {
    containerCssSelector: false,
    topThresholdCssSelector: false
  },
  visible: false,  
  topThreshold: 0,

  init: function(config){
    FN.sharingBar.config = config;
    if($(FN.sharingBar.config.containerCssSelector).length == 0) return;
    
    FN.sharingBar.resetTopThreshold();
    
    $(window).on('resize', function(){
      FN.sharingBar.resetTopThreshold();
    });

    $(window).on('scroll', function() {
      var scrollingPosition = $(window).scrollTop();
      FN.sharingBar.toggle(scrollingPosition > FN.sharingBar.topThreshold);
    });
  },
  
  toggle: function(show){
    if(show === FN.sharingBar.visible){ return; }
    var newFloatingSidebarPos = (show) ? '-6px' : '-70px';
    $(FN.sharingBar.config.containerCssSelector).css({ right: newFloatingSidebarPos});
    FN.sharingBar.visible = show;
  },
  
  resetTopThreshold: function(){
    var topThresholdElement = $(FN.sharingBar.config.topThresholdCssSelector);
    if(topThresholdElement.length == 0) return;
    
    FN.sharingBar.topThreshold = $(topThresholdElement).position().top;
  }
};
