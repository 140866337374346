/******************
* Create and insert a PopUp (for getting newsletter signups etc.)
*
*
* Documentation: see `popups.README.md`
*
*/

if(typeof FN == 'undefined') FN = {};

FN.popupConfig = {
  configuredItems: {},
  instances: {},
  
  /** Search configuredItems for a popup for _this_ page and then create/init it */
  initConfiguredItems: function(){
    FN.popupConfig.instances = {};
    var popupsForCurrentPage = FN.popupConfig._findActivePopupsForCurrentPage();
    for(var p=0; p<popupsForCurrentPage.length; p++){
      FN.popupConfig.createPopupWithConfig(popupsForCurrentPage[p]);
    }
  },
  
  /** Create a new FN.popup instance and add it */
  createPopupWithConfig: function(popupConfig){
    if(popupConfig.id in FN.popupConfig.instances) return;
    
    FN.popupConfig.instances[popupConfig.id] = new FN.popup(popupConfig);
  },
  
  /** adds a popup configuration */
  addPopupConfig: function(config){
    if(!config) return;
    
    FN.popupConfig.configuredItems[config.id] = config;
  },
  
  /**
    Search FN.popupConfig.configuredItems for an active popup configuration for this page
    Note:   - 'active' must be true
            - 'pagePaths' must contain a regEx that matches the current window.location.pathname
  */
  _findActivePopupsForCurrentPage: function(){
    var popupsForCurrentPage = [];
    for (var key in FN.popupConfig.configuredItems) {
      if(!FN.popupConfig.configuredItems[key].active) continue;
      
      for(var p=0; p<FN.popupConfig.configuredItems[key].pagePaths.length; p++){
        var re = new RegExp(FN.popupConfig.configuredItems[key].pagePaths[p]);
        var match = re.exec(window.location.pathname);
        if(match) popupsForCurrentPage.push(FN.popupConfig.configuredItems[key]);
      }
    }
    
    return popupsForCurrentPage;
  }
};



FN.popup = function(config){
  /**
    Create and initialize a configured popup
  */
  this._initWithConfig = function(popupConfig){
    if(!popupConfig) return false;
    
    this.id = popupConfig.id;
    this.config = popupConfig;
    if(this._checkHasBeenClosedBefore()) return false;

    var html = this._generateHtmlContent();
    html     = this._wrapHtmlContent(html);
    if(!html) return false;
    
    this._insertPopupHtml(html);
    this._attachEventHandlers();
    this._manageFirstDisplay();
    
    // add analytics event listeners
    var popupAnalyticsEvents = [
      { 'config': { 'selector': '#' + this.id + ' .fn-popup__newsletter-form.analytics--submit', 'triggerOnlyOnce': true },
        'event':  { 'eventCategory': 'newsletter', 'eventAction': 'signup', 'eventLabelSuffix': this.id }
      },
      { 'config': { 'selector': '#' + this.id + ' .fn-popup__button--link.analytics--click' },
        'event':  { 'eventCategory': 'page-interaction', 'eventAction': 'landing-page', 'eventLabelSuffix': this.id }
      },
      { 'config': { 'selector': '#' + this.id + ' .fn-popup__button--tweet.analytics--click' },
        'event':  { 'eventCategory': 'social', 'eventAction': 'share', 'eventLabelSuffix': this.id }
      }
    ];
    if('analytics' in FN) FN.analytics.addConfiguredEvents(popupAnalyticsEvents);
  };
  

  /**
    Insert the generated HTML into the DOM
  */
  this._insertPopupHtml = function(html){
    switch(this.config.type){
      case 'sidebar':
      case 'modal':
      case 'welcome-mat':
        $('body').append(html);
        break;
      
      case 'inline':
        var popupPlaceholders = $('.popup-placeholder');
        for(var i=0; i<popupPlaceholders.length; i++) {
          if($(popupPlaceholders[i]).data('popupId') === this.id){
            $(popupPlaceholders[i]).replaceWith(html);
          }
        }
        break;
    }
  };
  
  
  /**
    Wraps the HTML content blocks in HTML containers
  */
  this._wrapHtmlContent = function(contentHtml){
    var html = '';
    html += '<div id="'+ this.id +'" class="fn-popup fn-popup--'+ this.config.type +' fn-popup--hidden">';
    
    switch(this.config.type){
      case 'sidebar':
        html += '<div class="fn-popup__content-container">';
        html += '  <div class="fn-popup__close"></div>';
        html +=    contentHtml;
        html += '</div>';        
        break;
        
      case 'inline':
        html += '<div class="fn-popup__content-container">';
        html += '  <div class="fn-popup__close"></div>';
        html +=    contentHtml;
        html += '</div>';        
        break;
        
      case 'modal':
        html += '<div class="fn-popup__overlay"></div>';
        html += '<div class="fn-popup__content-container">';
        html += '  <div class="fn-popup__close"></div>';
        html +=    contentHtml;
        html += '</div>';        
        break;
        
      case 'welcome-mat':
        html += '<div class="fn-popup__overlay"></div>';
        html += '<div class="fn-popup__close"></div>';
        html += '<div class="fn-popup__content-container">' + contentHtml + '</div>';
        break;
    }

    return html;
  };
  
  
  /**
    Generate appropriate HTML
  */
  this._generateHtmlContent = function(){
    var html = '';
    for(var i=0; i<this.config.content.length; i++){
      var content = this.config.content[i];
      html += '<div class="fn-popup__content fn-popup__content--' + (i+1) + '">';

      switch(content.type){
        case 'content':
          html    += '<div class="fn-popup__text">'+ content['text'] +'</div>';
          html    += '<a class="fn-popup__button fn-popup__button--next">'+ content['action-button'] +'</a>';
          break;

        case 'link':
          html    += '<div class="fn-popup__text">'+ content['text'] +'</div>';
          html    += '<a class="fn-popup__button fn-popup__button--link analytics--click">'+ content['action-button'] +'</a>';
          break;

        case 'tweet':
          html    += '<div class="fn-popup__text">'+ content['text'] +'</div>';
          html    += '<a class="fn-popup__button fn-popup__button--tweet analytics--click">'+ content['action-button'] +'</a>';
          if('footer' in content){
            html    += '<div class="fn-popup__footer">'+ content['footer'] +'</div>';
          }
          break;
          
        case 'newsletter-signup':
          html    += '<div class="fn-popup__text">'+ content['text'] +'</div>';
          html    += '<div class="fn-popup__input">';
          html    += '  <form class="fn-popup__newsletter-form analytics--submit" autocomplete="off">';
          html    += '    <input type="hidden" name="action" value="subscribe" />';
          html    += '    <input type="hidden" name="format" value="json" />';
          html    += '    <input type="hidden" name="listName" value="'+ (content['list-name'] ? content['list-name'] : 'tower') +'" />';
          html    += '    <input type="hidden" name="signup[medium]" value="'+ content['signup-medium'] +'" />';
          html    += '    <input type="hidden" name="signup[URI]" value="'+ content['signup-URI'] +'" />';
          html    += '    <input type="hidden" name="signup[OS]" value="'+ FN.helpers.platform() +'" />';
          if('signup-form' in content){
            html  += '    <input type="hidden" name="signupForm" value="'+ content['signup-form'] +'" />';
          }
          if('tags' in content){
            html += '<div class="newsletter-form__groups">';
            for(var t=0; t<content['tags'].length; t++){
              var hidden = ('hidden' in content['tags'][t] && content['tags'][t]['hidden']) ? ' style="display:none;" ' : '';
              var defaultChecked = ('default' in content['tags'][t] && content['tags'][t]['default']) ? ' checked="checked" ' : '';
              var requiredTag = ('required' in content['tags'][t]) ? content['tags'][t]['required'] : '';
              var cssClass = 'signup-tag__item' + (('collectConsent' in content['tags'][t] && content['tags'][t]['collectConsent']) ? ' consent__text' : '');
              html  += '<div class="row"'+ hidden +'><label>';
              html  +=    '<input class="'+ cssClass +'" type="checkbox" name="tags[]" value="'+ content['tags'][t]['id'] +'" id="signup-tag__'+ content['tags'][t]['id'] +'"'+ defaultChecked +' data-required="'+ requiredTag +'" />';
              html  +=    ('label' in content['tags'][t]) ? content['tags'][t]['label'] : '';
              html  += '</label></div>';
            }
            html += '</div>';
          }
          if('consent' in content){
            html += '<div class="newsletter-form__consent">';
            for(var c=0; c<content['consent'].length; c++){
              html += '<label><input type="checkbox" id="consent--'+ content['consent'][c]['id'] +'" class="consent__item consent__text" data-required="'+ content['consent'][c]['required'] +'" />'+ content['consent'][c]['text'] +'</label>';
            }
            html += '</div>';
          }
          html    += '<div class="newsletter-form__submit">';
          html    +=    '<input type="text" name="email" placeholder="Email Address" />';
          html    +=    '<input type="submit" value="'+ content['action-button'] +'" class="fn-popup__button--signup" />';
          if('close-button' in content){
            html  += '    <a class="fn-popup__button fn-popup__button--close analytics--click">'+ content['close-button'] +'</a>';
          }
          html    += '</div>';
          html    += '    <div class="request-notice"></div>';
          html    += '  </form>';
          html    += '</div>';
          break;
      }
        
      html  += '</div>';
    }

    return html;
  };
  
  
  /**
    Manages the first display of the popup (either when reaching a certain scrolling position or directly after loading the page)
  */
  this._manageFirstDisplay = function(){
    if(this.config.display.event === 'onScroll'){
      $(window).on('scroll.fn-popup', $.proxy(this._showOnScroll, this));
    }else if(this.config.display.event === 'onLoad'){
      this._showOnLoad();
    }else if(this.config.display.event === 'onClick'){
      $(document).on('click', this.config.display.selector, $.proxy(this._showOnClick, this));
    }
  };
  
  
  this._showOnScroll = function() {
    var scrollingPosition = $(document).scrollTop() + $(window).height();

    if("beforeElement" in this.config.display){
      /*    'display': { 
              'event': 'onScroll',              // must be 'onScroll'
              'beforeElement': '#chapter ~',    // CSS selector of the element that the popup should display when reaching
              'withDistance': '500' }           // optional amount of pixels that the event should fire before reaching the element
      */
      var beforeElement = $(this.config.display.beforeElement);
      var offsetTop = (beforeElement.offset()).top;
      if("withDistance" in this.config.display) offsetTop -= this.config.display.withDistance;
      if(scrollingPosition > offsetTop){
        this._revealPopup();
        $(window).off('scroll.fn-popup');
      }
      
    }else if("percent" in this.config.display){
      /*  'display': { 
            'event': 'onScroll',    // must be 'onScroll'
            'percent': '0.7' }     // scrolling position, 0 to 1 (bottom of page)
      */
      var documentHeight = $(document).height();
      if(scrollingPosition / documentHeight > this.config.display.percent){
        this._revealPopup();
        $(window).off('scroll.fn-popup');
      }
    }
  };
  
  
  this._showOnLoad = function(){
    /*    'display': {
            'event': 'onLoad',    // must be 'onLoad'
            'delay': 500 }        // delay in miliseconds
    */
    var thePopup = this;
    setTimeout(function(){
      thePopup._revealPopup();
    }, thePopup.config.display.delay);
  };
  
  
  this._showOnClick = function(event){
    /*    'display': {
            'event': 'onClick',       // must be 'onClick'
            'selector': '.button' }   // CSS selector of the element(s) that should trigger the popup on click
    */
    event.preventDefault();
    var clickedElement = $(event.target);
    this._revealPopup();
  };
  
  
  /**
    Reveal (display for the first time) an already created popup
  */
  this._revealPopup = function(){
    var thePopup = this;
    if(this.config.type === 'modal'){
      $('#'+this.config.id).removeClass('fn-popup--hidden');
      $('#'+this.config.id + ' .fn-popup__content-container').fadeIn();
      
    }else if(this.config.type === 'welcome-mat'){
      $('#'+this.config.id).removeClass('fn-popup--hidden');
      
    }else if(this.config.type === 'sidebar'){
      if('startSmall' in this.config.display){
        $('#'+this.config.id).addClass('fn-popup--squashed');
      }
      $('#'+this.config.id).removeClass('fn-popup--hidden');
      setTimeout(function(){
        $('#' + thePopup.id + ' .fn-popup__content--1').fadeIn();
      }, 600);
      
    }else if(this.config.type === 'inline'){
      $('#'+this.config.id).removeClass('fn-popup--hidden');
    }
  };
  
  
  /**
    Attach event handlers
  */
  this._attachEventHandlers = function(){
    // --- close popup
    $('#'+ this.id + ' .fn-popup__close, #'+ this.id + ' .fn-popup__button--close').on('click', $.proxy(this.close, this));

    // --- show next content, if any
    $('#'+ this.id + ' .fn-popup__button--next').on('click', $.proxy(function(event){
      var currentContentOnClick = this._currentContentNumberOnClick($(event.target));
      this.nextOrClose(currentContentOnClick);
    }, this));

    // --- open link in new window
    $('.fn-popup__button--link').on('click', $.proxy(function(event){
      var currentContentOnClick = this._currentContentNumberOnClick($(event.target));
      window.open(this.config.content[currentContentOnClick-1]['url'], '_blank');
      this.nextOrClose(currentContentOnClick);
    }, this));
    
    // --- open tweet window
    $('.fn-popup__button--tweet').on('click', $.proxy(function(event){
      var currentContentOnClick = this._currentContentNumberOnClick($(event.target));
      var tweetOptions = this.config.content[currentContentOnClick-1]['tweet'];
      FN.socialShare(tweetOptions.platform, tweetOptions.url, tweetOptions.text);
      this.nextOrClose(currentContentOnClick);
    }, this));

    // --- register newsletter element
    var newsletterItem = $('#'+this.id).find('.fn-popup__newsletter-form');
    if(newsletterItem.length > 0){
      FN.newsletterConfig.instances.push(new FN.newsletter(newsletterItem));
      $(document).on("FN.newsletter:success", $.proxy(function(event, context){
        if($(context.options.responseContainer).parents("#"+this.id).length == 1) {
          this._newsletterSubmissionResponse(context.responseData, context.options);
        }
      }, this));
    }
  };
  
  
  /**
    Shows next content (of multi-step popup contents)
  */
  this._showNextContent = function(currentContentNo){
    var thePopup = this;
    // hide current content
    $('#'+ this.id +' .fn-popup__content--' + currentContentNo).hide();

    // if configured to grow after the first interaction...
    if('startSmall' in this.config.display && this.config.display['startSmall'] == true){
      if($('#'+ this.id).hasClass('fn-popup--squashed')){
        $('#'+ this.id).removeClass('fn-popup--squashed').addClass('fn-popup--stretched');
      }
    }

    // show next content
    if($('#'+ this.id +' .fn-popup__content--' + (parseInt(currentContentNo)+1))){
      setTimeout(function(){
        $('#'+ thePopup.id +' .fn-popup__content--' + (parseInt(currentContentNo)+1)).fadeIn();
      }, 500);
    }
  };
  
  
  /**
    Returns the number (id) of the currently shown content (typicall, either 1, 2, or 3)
  */
  this._currentContentNumberOnClick = function(clickedItem){
    var contentContainer = $(clickedItem).closest('.fn-popup__content');
    var contentId = contentContainer.attr('class').match(/fn-popup__content--(\d){1}/i);
    if(contentId && contentId[1]){
      return contentId[1];
    }else{
      return false;
    }
  };
  
  
  /**
    Check if a the popup has been closed on a previous pageview
  */
  this._checkHasBeenClosedBefore = function(){
    var re = new RegExp(this.id + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value !== null) ? true : false;
  };
  
  
  /**
    Save a cookie if the user has closed the popup (so he doesn't see it again on his next visit)
  */
  this._setPreventReopenCookie = function(){
    document.cookie = this.id +'=closed; path=/; expires=Tue, 07 Nov 2084 12:00:00 GMT';
  };
  
  
  /**
   Callback for async newsletter requests
  */
  this._newsletterSubmissionResponse = function(data, options){
    if(data.status === 200){
      var currentContentOnClick = this._currentContentNumberOnClick(options.responseContainer);
      this.nextOrClose(currentContentOnClick);
    }
  },
  
  
  /**
    Close any open popups
  */
  this.close = function(){
    $('#'+this.id).addClass('fn-popup--hidden');
    if('showOnlyOnce' in this.config.display && this.config.display.showOnlyOnce === false){
      // don't set a cookie
    }else{
      this._setPreventReopenCookie();
    }
  };
  
  
  /**
    Show next content or close the popup (if there is no "next" content...)
  */
  this.nextOrClose = function(currentContentNo){
    var numberOfContents = this.config.content.length;
    if(numberOfContents > currentContentNo){
      this._showNextContent(currentContentNo);
    }else if(numberOfContents == currentContentNo){
      this.close();
    }
  }

  /*****************
  * Constructor
  */
  this._initWithConfig(config);
};




// load configured popups - but only once the page is completely loaded
$( window ).on('load', function(){
  FN.popupConfig.initConfiguredItems();
});
