if(typeof FN == 'undefined') FN = {};


FN.couponParam = {
  config: {
    urlParamName: 'coupon'
  },
  
  
  init: function() {
    var couponParamValue = FN.helpers.getURLParameterByName(FN.couponParam.config.urlParamName);
    
    if(couponParamValue){
      Cookies.set(FN.couponParam.config.urlParamName, couponParamValue, { expires: 7 });
    }
  }
};




$(window).on('load', FN.couponParam.init);
