if(typeof FN == 'undefined') FN = {};


FN.videoAutoplayerConfig = {};


FN.videoAutoplayer = {
  init: function(){
    // start playing videos that might be visible already now, on pageLoad
    FN.videoAutoplayer.playOrPauseAllVideos();
    // scroll event handler
    $(window).on('scroll', FN.videoAutoplayer.playOrPauseAllVideos);
  },

  /********************
  * Iterate through all videos on the page and
  * play or pause them depending on their visibility
  */
  playOrPauseAllVideos: function(){
    $('video').each(function(i, item){
      var isVideoVisible = FN.videoAutoplayer.isVideoVisible(item);
      FN.videoAutoplayer.playOrPause(item, isVideoVisible);
    });
  },

  /********************
  * Returns true if the given video item is both
  * visible in the viewport and visible through CSS
  */
  isVideoVisible: function(item){
    // is the video currently visible on the page / viewport?
    var visibleInViewport = FN.helpers.isVisibleInViewport({element: item, minimumVisiblePercent:40});
    // is the video currently visible by CSS properties (note that we have to check the _parent_ element)
    var visibleInCSS = $(item).parent().css(['opacity', 'visibility', 'display']);

    if(visibleInViewport && visibleInCSS.opacity > 0 && visibleInCSS.visibility != 'hidden' && visibleInCSS.display != 'none'){
      return true;
    }else{
      return false;
    }
  },

  playOrPause: function(item, isVideoVisible){
    if(isVideoVisible){
      FN.videoAutoplayer.startPlayback(item);
    }else{
      FN.videoAutoplayer.stopPlayback(item);
    }
  },

  startPlayback: function(videoElement) {
    var videoDomElement = $(videoElement).get(0);
    var isPlaying = videoDomElement.currentTime > 0 && !videoDomElement.paused && !videoDomElement.ended && videoDomElement.readyState > 2;
    if(!isPlaying){      
      var playPromise = videoDomElement.play();
      if (playPromise !== undefined) {
        playPromise.then(function() {
          // Autoplay started!
        }).catch(function(error) {
          // Autoplay was prevented --> show a "Play" button so that user can start playback
          $(videoDomElement).prop('controls', true);
        });
      }

      return true;
    }
  },

  stopPlayback: function(videoElement) {
    var videoDomElement = $(videoElement).get(0);
    var isPlaying = videoDomElement.currentTime > 0 && !videoDomElement.paused && !videoDomElement.ended && videoDomElement.readyState > 2;
    if(isPlaying){
      videoDomElement.pause();
      return true;
    }
  },
  
  rewindToStart: function(videoElement){
    var videoDomElement = $(videoElement).get(0);
    videoDomElement.currentTime = 0;
    return true;
  }
};



/********************
* --USAGE--
* Typically on pageReady, you'll simply want to call "init()" on the videoAutoplayer as follows:
*--------------------

$(document).ready(function(){
  FN.videoAutoplayer.init();
});

**********************/
