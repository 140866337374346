if(typeof FN == 'undefined') FN = {};

FN.downloadLink = {
  selector: '.download-link',
  
  init: function() {
    $(window).on('click', FN.downloadLink.selector, function(event){
      event.preventDefault();
      var clickedElement = $(event.target);
      FN.downloadLink.startDownload(clickedElement);
    });
  },
  
  startDownload: function(clickedElement){
    if(!$(clickedElement).is('a')) return;
    
    var url = $(clickedElement).attr('href');
    var iFrame = '<iframe style="display: none !important" src="'+ url +'">';
    $(iFrame).appendTo('body');
  }
};



$(document).on('ready', function(){
  FN.downloadLink.init();
});
