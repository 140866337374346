if(typeof FN == 'undefined') FN = {};


FN.contentTextWithMedia = function(itemConfig){
  /****************
  * Initialization
  */
  this.__init = function(itemConfig){
    this.platform = (typeof FN.osSwitch == 'undefined' || !FN.osSwitch.currentPlatform) ? FN.helpers.platform() : FN.osSwitch.currentPlatform;
    this.itemConfig = itemConfig;

    // build + insert HTML
    this.__buildItemHtml();
    if('navigation' in this.itemConfig && this.itemConfig['navigation'] === true){
      this.__buildNavigationHtml();
    }

    // find + save DOM elements
    this.baseElement = $('#' + this.itemConfig['id']);
    this.mediaItems = $(this.baseElement).find('.' + FN.contentTextWithMedia.static.selectors.mediaItemClass);
    this.textItems = $(this.baseElement).find('.' + FN.contentTextWithMedia.static.selectors.textItemClass);
    this.navigationItems = $(this.baseElement).find('.' + FN.contentTextWithMedia.static.selectors.navigationItemClass);
    this.activeItemIndex = this.__findActiveItemIndex();

    // set up event handlers
    $(this.textItems).on('click', $.proxy(this.itemWasSelected, this));
    $('video', this.baseElement).on('ended', $.proxy(this.videoFinishedPlaying, this));
    $('video', this.baseElement).on('loadeddata', $.proxy(this.videoHasLoadedData, this));
    if('navigation' in this.itemConfig && this.itemConfig['navigation'] === true){
      $(this.navigationItems).on('click', $.proxy(this.itemWasSelected, this));
    }

    // initialize with current selection
    this.__displayActiveItem();
  };
  
  
  /*****************
  * Build HTML structure of item
  */
  this.__buildItemHtml = function(){
    var html = '';
    var contentConfig = this.__contentConfig();
    var containerClass = ('width' in this.itemConfig) ? 'inner-container--' + this.itemConfig['width'] : 'inner-container--fullwidth';

    html += '<div class="inner-container '+ containerClass +'">';
    for(var key in contentConfig){
      if(key === 'text'){
        var textContent = contentConfig['text'];
        html += this.__buildItemHtmlForTextContent(textContent);

      }else if(key === 'media'){
        var mediaContent = contentConfig['media'];
        var mediaItems = mediaContent['items'];
        html += this.__buildItemHtmlForMediaContent(mediaContent, mediaItems);
      }
    }
    html += '</div>';

    $('#' + this.itemConfig['id']).html(html);
  };


  this.__buildItemHtmlForTextContent = function(textContent){
    html =  '<div class="text-with-media__text">';
    html += ' <div class="text__container">' + FN.helpers.absoluteUrl(textContent) + '</div>';
    html += '</div>';
    
    return html;
  };
  
  
  this.__buildItemHtmlForMediaContent = function(mediaContent, mediaItems){
    html =  '<div class="text-with-media__media">';
    html += ' <div class="media__container">';
    
    // placeholder images
    html += '<div class="media__placeholder">';
    html += this.__buildItemHtmlForPlaceholder();
    html += '</div>';

    // media items
    for(var m=0; m<mediaItems.length; m++){
      var mediaItem = mediaItems[m];
      var mediaItemSource = this.__bestItemSourceForCurrentScreen(mediaItem);
      if(mediaItemSource['type'] === 'video'){
        html += this.__buildItemHtmlForVideo(mediaItemSource, mediaItem);
      }else if(mediaItemSource['type'] === 'picture'){
        html += this.__buildItemHtmlForPicture(mediaItemSource, mediaItem);
      }
    }
    
    html += '</div><!-- END media__container-->';
    html += '</div><!-- END media-->';
    
    return html;
  };
  
  
  this.__buildItemHtmlForPlaceholder = function(){
    var placeholderImagePath = this.__urlForPlaceholderImage();
    var currentBreakpoint = this.__findMatchingMediaBreakpoint();
    var html = ""
    switch (currentBreakpoint['media']) {
      case "l":
        html += '<img src="'+ placeholderImagePath +'" width="1500" height="1200" />'
        break
      case "m":
        html += '<img src="'+ placeholderImagePath +'" width="2000" height="1200" />'
        break
      default:
        html += '<img src="'+ placeholderImagePath +'" />'
    }
    return html;
  };
  
  
  this.__buildItemHtmlForVideo = function(mediaItemSource, mediaItem){
    var itemClass = FN.contentTextWithMedia.static.selectors.mediaItemClass;

    html = '<div class="'+ itemClass +'" data-source-media="'+ mediaItemSource['media'] +'">';
    html += '<video preload="metadata" playsinline="playsinline" muted="muted" class="loading">';

    // main video source
    if('src' in mediaItemSource) {
      var mainSourceUrl = FN.helpers.absoluteUrl(mediaItemSource['src']);
      html += '<source src="'+ mainSourceUrl +'" type="video/mp4">';
    }
    // fallback sources
    if('fallback' in mediaItemSource) {
      for(var f=0; f<mediaItemSource['fallback'].length; f++) {
        var fallbackUrl = FN.helpers.absoluteUrl(mediaItemSource['fallback'][f]);
        if(mediaItemSource['fallback'][f].match(".mp4$")){
          html += '<source src="'+ fallbackUrl +'" type="video/mp4">';
        }else{
          switch (mediaItemSource.media) {
            case "l":
              html += '<img src="'+ fallbackUrl +'" width="1500" height="1200" />'
              break
            case "m":
              html += '<img src="'+ fallbackUrl +'" width="2000" height="1200" />'
              break
            default:
              html += '<img src="'+ fallbackUrl +'" />'
          }
        }
      }
    }

    html += '</video>';
    html += '</div>';
    
    return html;
  };


  this.__buildItemHtmlForPicture = function(mediaItemSource, mediaItem){
    var itemClass = FN.contentTextWithMedia.static.selectors.mediaItemClass;
    var srcSet = ('srcset' in mediaItemSource) ? FN.helpers.absoluteUrl(mediaItemSource['srcset']) : '';
    var imgSrc = ('src' in mediaItemSource) ? FN.helpers.absoluteUrl(mediaItemSource['src']) : '';
    
    html = '<div class="'+ itemClass +'" data-source-media="'+ mediaItemSource['media'] +'">';
    html += '<picture>';
    html += ' <source srcset="'+ srcSet +'" />';
    html += ' <img src="'+ imgSrc +'" />';
    html += '</picture>';
    html += '</div>';
    
    return html;
  };


  this.__buildNavigationHtml = function() {
    var contentConfig = this.__contentConfig()
    var mediaContent = contentConfig['media']['items'];
    var html = '<div class="media__navigation">';
    for(var m=0; m<mediaContent.length; m++){
      html += '<a class="'+ FN.contentTextWithMedia.static.selectors.navigationItemClass +'"></a>';
    }
    html += '</div>';

    $('#' + this.itemConfig['id'] + ' .media__container').append(html);
  }


  this.__contentConfig = function() {
    // find the corresponding content config - depending on the OS / platform and the fact if "content_all" is defined
    if('content_all' in this.itemConfig) {
      return this.itemConfig['content_all'];
    }else if('content_'+this.platform in this.itemConfig){
      return this.itemConfig['content_'+this.platform];
    }else{
      return false;
    }
  }
  

  /*****************
  * Event handling: item was selected
  */
  this.itemWasSelected = function(event){
    // deactivate the automatic timer
    clearTimeout(this.activeItemRotationTimer);
    this.activeItemRotationTimer = false;
    this.itemConfig['autorotate'] = false;
    
    // mark active item
    var newSelectionIndex = false;
    var selectedItem = event.currentTarget;
    var selectedItemClass = $(event.currentTarget).hasClass(FN.contentTextWithMedia.static.selectors.navigationItemClass) ? FN.contentTextWithMedia.static.selectors.navigationItemClass : FN.contentTextWithMedia.static.selectors.textItemClass;
    var items = $(this.baseElement).find('.' + selectedItemClass);

    for(var i=0; i<items.length; i++){
      if($(items[i]).is(selectedItem)){
        if(this.activeItemIndex === i){
          // current highlight was re-selected
          return;
        }else{
          this.activeItemIndex = i;
        }
        break;
      }
    }

    this.__hideActiveItem();
    this.__displayActiveItem();
    event.preventDefault();
  };


  /*****************
  * Video finished playing
  */
  this.videoFinishedPlaying = function(event){
    if('autorotate' in this.itemConfig && this.itemConfig['autorotate'] === true){
      // show next item
      this.activateNextItem();
    }else{
      // replay video
      var videoElement = event.target;
      FN.videoAutoplayer.startPlayback(videoElement);
    }
  };


  /*****************
  * Video has loaded
  */
  this.videoHasLoadedData = function(event){
    // display the video
    var videoElement = event.target;
    $(videoElement).removeClass('loading').addClass('ready');
  };


  /*****************
  * Mark the "next" item as active
  */
  this.activateNextItem = function(){
    this.__hideActiveItem();
    if(this.activeItemIndex + 1 < this.textItems.length){
      this.activeItemIndex = this.activeItemIndex + 1;
    }else{
      this.activeItemIndex = 0;
    }
    this.__displayActiveItem();
  };


  /*****************
  * Check if we need to re-init a media item because a different breakpoint size
  * would be more suitable (possibly the case after the window was resized)
  */
  this.__checkMediaItemSizes = function(event) {
    var contentConfig = this.__contentConfig();
    if(!contentConfig) return;

    var mediaItemsConfig = contentConfig['media']['items'];
    var mediaItemConfig,
        mediaItemNode,
        currentItemSource,
        bestItemSourceWithCurrentScreenSize;

    for(var i=0; i<mediaItemsConfig.length; i++){
      mediaItemConfig = mediaItemsConfig[i];
      mediaItemNode = this.mediaItems[i];
      currentItemSource = $(mediaItemNode).data('source-media');
      bestItemSourceWithCurrentScreenSize = this.__bestItemSourceForCurrentScreen(mediaItemConfig);
      if(currentItemSource && bestItemSourceWithCurrentScreenSize && (currentItemSource != bestItemSourceWithCurrentScreenSize['media'])) {
        // re-init, in order to replace with more appropriate source
        this.__init(this.itemConfig);
        return;
      }
    }
  };


  /*****************
  * Find the currently active item
  */
  this.__findActiveItemIndex = function(){
    for(var i=0; i<this.textItems.length; i++){
      if($(this.textItems[i]).hasClass(FN.contentTextWithMedia.static.selectors.activeItemClass)){
        return i;
      }
    }
    return 0;
  };
  
  
  /*****************
  * From the sources defined for this item, find the one that matches the current
  * screen / breakpoint best
  */
  this.__bestItemSourceForCurrentScreen = function(mediaItem) {
    for(var s=0; s<mediaItem['sources'].length; s++){
      var breakpoint = FN.helpers.getResponsiveBreakpointByName(mediaItem['sources'][s]['media']);
      if (window.matchMedia('(min-width: '+ breakpoint['width'] +')').matches) {
        return mediaItem['sources'][s];
      }
    }
    return false;
  };
  

  /*****************
  * Determines the correct placeholder image size and returns the according image URL
  */
  this.__urlForPlaceholderImage = function(){
    var currentBreakpoint = this.__findMatchingMediaBreakpoint();
    var placeholderSize;
    for(var p=0; p<FN.contentTextWithMedia.static.placeholderSizes.length; p++){
      if(FN.contentTextWithMedia.static.placeholderSizes[p]['media'] == currentBreakpoint['media']){
        placeholderSize = FN.contentTextWithMedia.static.placeholderSizes[p];
        break;
      }
    }
    var placeholderImagePath = FN.helpers.absoluteUrl('<base/>assets/img/screenshots/' + placeholderSize['image']);

    return placeholderImagePath;
  };


  /*****************
  * Displays / marks the currently active item
  */
  this.__displayActiveItem = function(){
    $(this.textItems[this.activeItemIndex]).addClass(FN.contentTextWithMedia.static.selectors.activeItemClass);
    $(this.mediaItems[this.activeItemIndex]).addClass(FN.contentTextWithMedia.static.selectors.activeItemClass);
    $(this.navigationItems[this.activeItemIndex]).addClass(FN.contentTextWithMedia.static.selectors.activeItemClass);

    var contentConfig = this.__contentConfig();
    if(!contentConfig || !('media' in contentConfig)) return;
    
    var activeMediaItem = contentConfig['media']['items'][this.activeItemIndex];
    var videoElement = $(this.mediaItems[this.activeItemIndex]).find('video');
    if(videoElement.length == 1){
      var visibleInViewport = FN.helpers.isVisibleInViewport({element: videoElement, minimumVisiblePercent:40});
      if(visibleInViewport) FN.videoAutoplayer.startPlayback(videoElement);
    }
    
    this.__startActiveItemRotation();
  };


  /*****************
  * Hides the currently active item before switching to the next (now 'active') item
  */
  this.__hideActiveItem = function(){
    var contentConfig = this.__contentConfig();
    var activeMediaItem = contentConfig['media']['items'][this.activeItemIndex];
    var videoElement = $(this.mediaItems[this.activeItemIndex]).find('video');
    if(videoElement.length == 1){
      FN.videoAutoplayer.stopPlayback(videoElement);
      FN.videoAutoplayer.rewindToStart(videoElement);
    }
    
    $(this.textItems).removeClass(FN.contentTextWithMedia.static.selectors.activeItemClass);
    $(this.mediaItems).removeClass(FN.contentTextWithMedia.static.selectors.activeItemClass);
    $(this.navigationItems).removeClass(FN.contentTextWithMedia.static.selectors.activeItemClass);
  };


  /*****************
  * Activate the timer to rotate active items
  */
  this.__startActiveItemRotation = function(){
    // start autorotation timer (if configured)
    if(!'autorotate' in this.itemConfig || this.itemConfig['autorotate'] !== true) return;

    var instance = this;
    var rotationTiming = false;

    var contentConfig = this.__contentConfig();
    var activeMediaItem = contentConfig['media']['items'][this.activeItemIndex];
    var videoElement = $(this.mediaItems[this.activeItemIndex]).find('video');
    if(videoElement.length == 1){
      // videos rotate through the "ended" event listener
      return;
    }else{
      rotationTiming = FN.contentTextWithMedia.static.rotationTiming;
    }
    
    this.activeItemRotationTimer = setTimeout(function(){
      instance.activateNextItem();
    }, rotationTiming);
  };


  /*****************
  * Find the responsive breakpoint that matches the current screen width
  */
  this.__findMatchingMediaBreakpoint = function() {
    var currentBreakpoint = false;
    for(var b=0; b<FN.config.responsiveBreakpoints.length; b++){
      if (window.matchMedia('(min-width: '+ FN.config.responsiveBreakpoints[b]['width'] +')').matches) {
        currentBreakpoint = FN.config.responsiveBreakpoints[b];
        break;
      }
    }
    return currentBreakpoint;
  };
  
  
  /*****************
  * Constructor
  */
  this.__init(itemConfig);
};




/**************
* Static Variables
*/
FN.contentTextWithMedia.static = {
  itemInstances: [],
  rotationTiming: 4000,
  selectors: {
    itemWrapperClass:  'text-with-media',
    mediaItemClass: 'media__item',
    textItemClass: 'text__item',
    navigationItemClass: 'navigation__item',
    activeItemClass: 'active'
  },
  placeholderSizes: [
    { 'media': 'xl', 'image': 'placeholder@1500x1200.png' },
    { 'media': 'l',  'image': 'placeholder@1500x1200.png' },
    { 'media': 'm',  'image': 'placeholder@2000x1200.png' },
    { 'media': 's',  'image': 'placeholder@400x500.png' },
    { 'media': 'xs', 'image': 'placeholder@400x500.png' }
  ],
  
  initAllItems: function(){
    if('contentTextWithMediaItems' in FN){
      for(var i=0; i<FN.contentTextWithMediaItems.length; i++){
        var itemConfig = FN.contentTextWithMediaItems[i];
        FN.contentTextWithMedia.static.itemInstances.push(new FN.contentTextWithMedia(itemConfig));
      }
    }
    
    // start playing videos that might be visible already now, on pageLoad
    if(typeof FN.videoAutoplayer != 'undefined') FN.videoAutoplayer.playOrPauseAllVideos();
    
    // replace testimonial placeholders
    if(typeof FN.testimonials != 'undefined') FN.testimonials.init();
    
    // react on window resize
    $(window).on('resize', FN.contentTextWithMedia.static.windowWasResized);
  },
  
  windowWasResized: function(event){
    for(var i=0; i<FN.contentTextWithMedia.static.itemInstances.length; i++){
      var contentTextWithMediaItem = FN.contentTextWithMedia.static.itemInstances[i]
      contentTextWithMediaItem.__checkMediaItemSizes();
    }
    if(typeof FN.testimonials != 'undefined') FN.testimonials.init();
  }
};
