if(typeof FN == 'undefined') FN = {};


FN.contentSlider = function(baseElement){
  /****************
  * Initialization
  */
  this.__init = function(baseElement){
    this.platform = FN.helpers.platform();
    this.baseElement = baseElement;
    
    // find + save elements
    this.allItems = $(this.baseElement).find('.' + FN.contentSlider.static.selectors.itemClass);
    this.activeItem = $(this.baseElement).find('.' + FN.contentSlider.static.selectors.activeItemClass);
    if(this.activeItem.length != 1) this.__makeItemActive(this.allItems[0]);
    
    // build + insert controls HTML
    this.__buildControlsHtml();
    this.__buildNavigationHtml();
    this.__markActiveNavigationItem();
    
    // set up event triggers
    $(this.baseElement).find('.' + FN.contentSlider.static.selectors.controlPreviousClass).on('click', $.proxy(this.__showPreviousItemClicked, this));
    $(this.baseElement).find('.' + FN.contentSlider.static.selectors.controlNextClass).on('click', $.proxy(this.__showNextItemClicked, this));
    $(this.baseElement).find('.' + FN.contentSlider.static.selectors.navigationItemClass).on('click', $.proxy(this.__navigationItemClicked, this));
    
    // start autorotation
    this.__startActiveItemRotation();
  };


  /*****************
  * Build next/previous controls HTML structure
  */
  this.__buildControlsHtml = function(){
    var html = '';

    // add controls
    html += '<div class="content-slider__control--previous"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 25"><path d="M13 0a1 1 0 0 1 .7.29 1 1 0 0 1 0 1.39L2.81 12.5l10.9 10.82a1 1 0 0 1 0 1.39 1 1 0 0 1-1.41 0L0 12.5 12.3.29A1 1 0 0 1 13 0" fill="#51bbf1" /></svg></div>';
    html += '<div class="content-slider__control--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 25"><path d="M1 25a1 1 0 0 1-.7-.29 1 1 0 0 1 0-1.39L11.19 12.5.29 1.68a1 1 0 0 1 0-1.39 1 1 0 0 1 1.41 0L14 12.5 1.7 24.71A1 1 0 0 1 1 25" fill="#51bbf1" /></svg></div>';
    
    $(this.baseElement).prepend(html);
  };


  /*****************
  * Build navigation HTML structure
  */
  this.__buildNavigationHtml = function(){
    var html = '';

    // add navigation, one for each item
    html += '<div class="'+ FN.contentSlider.static.selectors.navigationWrapperClass +'">';
    for(var i=0; i<this.allItems.length; i++){
      html += '<a class="'+ FN.contentSlider.static.selectors.navigationItemClass +'"></a>';
    }
    html += '</div>';
    
    $(this.baseElement).append(html);
  };


  /*****************
  * Next item button was clicked
  */
  this.__showNextItemClicked = function(event){
    clearInterval(this.autorotateTimer);
    this.__showNextItem(event);
  };
  
  
  
  /*****************
  * Previous item button was clicked
  */
  this.__showPreviousItemClicked = function(event){
    clearInterval(this.autorotateTimer);
    this.__showPreviousItem(event);
  };
  
  
  /*****************
  * Navigation item was clicked
  */
  this.__navigationItemClicked = function(event) {
    clearInterval(this.autorotateTimer);
    var selectedItemIndex, 
        direction;
    var activeItemIndex = this.__activeItemIndex();
    var selectedItem = event.target;
        
    var navigationItems = $(this.baseElement).find('.'+ FN.contentSlider.static.selectors.navigationItemClass);
    for(var i=0; i<navigationItems.length; i++) {
      if($(navigationItems[i]).is(selectedItem)){
        selectedItemIndex = i;
        direction = (selectedItemIndex > activeItemIndex) ? 'next' : 'previous';
        break;
      }
    }
      
    this.__makeItemActive(this.allItems[selectedItemIndex], direction);
  };
  
  
  /*****************
  * Shows next item
  */
  this.__showNextItem = function(event){
    var nextItemIndex = false;
    var currentItemIndex = this.__activeItemIndex();
    if(currentItemIndex >= this.allItems.length-1){
      nextItemIndex = 0;
    }else{
      nextItemIndex = currentItemIndex + 1;
    }
    this.__makeItemActive(this.allItems[nextItemIndex], 'next');
  };
  
  
  /*****************
  * Shows previous item
  */
  this.__showPreviousItem = function(event){
    var previousItemIndex = false;
    var currentItemIndex = this.__activeItemIndex();
    if(currentItemIndex == 0){
      previousItemIndex = this.allItems.length - 1;
    }else{
      previousItemIndex = currentItemIndex - 1;
    }
    this.__makeItemActive(this.allItems[previousItemIndex], 'previous');
  };


  /*****************
  * Make an item active
  */
  this.__makeItemActive = function(newActiveItem, direction) {
    var hideClass = (direction === 'next') ? FN.contentSlider.static.selectors.hideRightItemClass : FN.contentSlider.static.selectors.hideLeftItemClass;
    $(this.activeItem).removeClass(FN.contentSlider.static.selectors.activeItemClass)
                      .addClass(hideClass);
    $(newActiveItem).removeClass(FN.contentSlider.static.selectors.hideLeftItemClass + ' ' + FN.contentSlider.static.selectors.hideRightItemClass)
                   .addClass(FN.contentSlider.static.selectors.activeItemClass);
    this.activeItem = $(newActiveItem);
    this.__markActiveNavigationItem();
  };
  
  
  /*****************
  * Returns the index of the currently active item
  */
  this.__activeItemIndex = function() {
    for(var i=0; i<this.allItems.length; i++){
      if($(this.allItems[i]).is(this.activeItem)) {
        return i;
      }
    }
    return false;  
  };
  
  
  /*****************
  * Marks the currently active navigation item
  */
  this.__markActiveNavigationItem = function() {
    var activeItemIndex = this.__activeItemIndex();
    var navigationItems = $(this.baseElement).find('.'+ FN.contentSlider.static.selectors.navigationItemClass);
    for(var i=0; i<navigationItems.length; i++) {
      if(i === activeItemIndex){
        $(navigationItems[i]).addClass('active');
      }else{
        $(navigationItems[i]).removeClass('active');
      }
    }
  };
  
  
  /*****************
  * Activate the timer/interval to rotate items automatically
  */
  this.__startActiveItemRotation = function(){
    // start autorotation timer (if configured)
    var autorotationConfig = $(this.baseElement).data('autorotate');
    if(!autorotationConfig || isNaN(autorotationConfig)) return;
    
    var instance = this;
    this.autorotateTimer = setInterval(function(){
      instance.__showNextItem();
    }, parseInt(autorotationConfig));
  };


  /*****************
  * Constructor
  */
  this.__init(baseElement);
};

/**************
* Static Variables
*/
FN.contentSlider.static = {
  itemInstances: [],
  selectors: {
    wrapperClass:  'content-slider',
    itemClass: 'content-slider__item',
    controlPreviousClass: 'content-slider__control--previous',
    controlNextClass: 'content-slider__control--next',
    navigationWrapperClass: 'content-slider__navigation',
    navigationItemClass: 'content-slider__navigation-item',
    activeItemClass: 'active',
    hideLeftItemClass: 'hidden-left',
    hideRightItemClass: 'hidden-right'
  }
};




$(document).ready(function(){
  $('.' + FN.contentSlider.static.selectors.wrapperClass).each(function(index, element){
    FN.contentSlider.static.itemInstances.push(new FN.contentSlider(element));
  });
});
