if(typeof FN == 'undefined') FN = {};


FN.topBar = {
  active: false,
  visible: false,
  topThreshold: 0,
  selectors: {
    baseElementClass: 'top-bar',
    topThresholdElement: '.text-with-media:last-of-type'
  },

  init: function(){
    FN.topBar.active = true;
    FN.topBar.resetTopThreshold();

    $(window).resize(function(){
      FN.topBar.resetTopThreshold();
    });

    $(window).scroll(function() {
      var scrollingPosition = $(window).scrollTop();
      FN.topBar.toggle(scrollingPosition > FN.topBar.topThreshold);
    });
  },

  resetTopThreshold: function(){
    if($(FN.topBar.selectors.topThresholdElement).length < 1) return;
    FN.topBar.topThreshold = $(FN.topBar.selectors.topThresholdElement).position().top;
  },

  toggle: function(show){
    if(FN.topBar.active === false){ return; }
    if(show === FN.topBar.visible){ return; }
    var newTopBarPos = (show) ? '0px' : '-10em';
    $('.' + FN.topBar.selectors.baseElementClass).stop().animate({ top: newTopBarPos});
    FN.topBar.visible = show;
  }
};
