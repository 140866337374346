/******************
* Helper functions
*******************/
if(typeof FN == 'undefined') FN = {};

FN.helpers = {
  /****************
  * Returns true if the display is HDPI
  */
  isRetinaDisplay: function(){
    return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
  },
  
  /****************
  * Returns true if the device is a mobile one (iPhone, iPad, Android...)
  */
  isMobileDevice: function() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  },

  /****************
  * Returns the value of a given URL parameter
  */
  getURLParameterByName: function(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  },

  /****************
  * Returns the name of the user's Operating System
  */
  platform: function(){
    if(navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)){
      return 'mac';
    }else if(navigator.platform.match(/(Win)/i)){
      return 'windows';
    }else{
      return 'other';
    }
  },

  /****************
  * Returns true if the given element is visible in the viewport
  * As optional parameters, a minimum amount of visible Pixels or percent can be specified
  * options = {element: <jQuery el>, minimumVisiblePixels: <number>, minimumVisiblePercent: <number>}
  */
  isVisibleInViewport: function(options){
    var elementHeight = $(options.element).outerHeight();
    var elementTop = $(options.element).offset().top;
    var elementBottom = elementTop + elementHeight;
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    var minimumVisiblePixels = 0;
    if(options.minimumVisiblePixels) minimumVisiblePixels = options.minimumVisiblePixels;
    if(options.minimumVisiblePercent) minimumVisiblePixels = (options.minimumVisiblePercent / 100) * elementHeight;

    var alreadyOnPage = (elementBottom - minimumVisiblePixels) > viewportTop;
    var notYetOffPage = (elementTop + minimumVisiblePixels) < viewportBottom;

    return alreadyOnPage && notYetOffPage;
  },

  /**********
  * Takes a relative URL to page or asset and returns a correct URL path
  * This happens simply by replacing the following placeholder in the given string:
  *   * "<base/>"
  *
  * Note: FN.config.urls.base must be set!
  */
  absoluteUrl: function(url){
    var transformedUrl = url;
    var pattern, regex;
    
    if('config' in FN && 'urls' in FN.config && 'base' in FN.config.urls) {
      pattern = "<base\\/>";
      regex = new RegExp(pattern, 'g');
      transformedUrl = transformedUrl.replace(regex, FN.config.urls.base);
    }

    return transformedUrl;
  },
  
  /*********
  * Search all defined "responsiveBreakpoints" and return the pixel size for a given breakpointName ("m", "xl"...)
  */
  getResponsiveBreakpointByName: function(breakpointName) {
    if(!'config' in FN || !'responsiveBreakpoints' in FN.config) return false;
    
    for(var b=0; b<FN.config.responsiveBreakpoints.length; b++){
      if(FN.config.responsiveBreakpoints[b]['media'] === breakpointName){
        return FN.config.responsiveBreakpoints[b];
      }
    }
  }

};
