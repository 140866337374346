if (window.FN == undefined) {
  FN = {};
}

// This function is basically a direct translation of the PHP version in
// siteURL/index.php.
FN.siteURL = function (site, path) {
  var url;
  var trailingSlash, leadingSlash;

  if (!FN.siteURLs || !FN.siteURLs[site]) {
    return '';
  }

  url = FN.siteURLs[site];

  trailingSlash = url[url.length - 1] == '/';
  leadingSlash = Boolean(path) && path[0] == '/';

  if (!trailingSlash && !leadingSlash && path) {
    url = url + '/' + path;
  }
  else if (trailingSlash && leadingSlash) {
    url = url + path.slice(1);
  }
  else if (path) {
    url = url + path;
  }

  return url;
};
