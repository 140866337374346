/******************
* Share on Twitter / Google+
*******************/
if(typeof FN == 'undefined') FN = {};

FN.socialShare = function(service, shareUrl, shareText){
  var serviceUrl;
  if(!shareText){ shareText = window.document.title; }
  if(!shareUrl){ shareUrl   = window.location.href; }
  
  if(service === 'twitter'){
    serviceUrl = 'http://www.twitter.com/share';
    serviceUrl += '?text=' + encodeURIComponent(shareText);
    serviceUrl += '&url=' + encodeURIComponent(shareUrl);
    
    window.open(serviceUrl, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
    
  }else if(service === 'facebook'){
    serviceUrl = 'https://www.facebook.com/sharer/sharer.php';
    serviceUrl += '?u=' + encodeURIComponent(shareUrl);

    window.open(serviceUrl, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
    
  }else if(service === 'linkedin'){
    serviceUrl = 'http://www.linkedin.com/shareArticle';
    serviceUrl += '?mini=true';
    serviceUrl += '&url=' + encodeURIComponent(shareUrl);
    serviceUrl += '&title=' + encodeURIComponent(shareText);

    window.open(serviceUrl, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
    
  }else if(service === 'buffer'){
    serviceUrl = 'https://bufferapp.com/add';
    serviceUrl += '?url=' + encodeURIComponent(shareUrl);
    serviceUrl += '&text=' + encodeURIComponent(shareText);

    window.open(serviceUrl, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
  }
};
