/******************
* Overlay
*
* 
* Creates a simple HTML container element that overlays (and possibly dims) the whole viewport.
* Often used to show popup windows.
*
*******************/

if(typeof FN == 'undefined') FN = {};

FN.overlay = {
  config: {
    fadeDuration: 500
  },
  selectors: {
    baseElementClass: 'overlay__container',
    closeButtonClass: 'overlay__button--close',
    contentElementClass: 'overlay__content'
  },


  init: function(options){
    var baseElement = $(options.baseElement);

    // close button event handling
    $(baseElement).find('.'+FN.overlay.selectors.closeButtonClass).on('click', FN.overlay.close);
    // show
    FN.overlay.show(baseElement);
  },


  show: function(baseElement){
    $(baseElement).addClass('visible');
    FN.overlay.showContent(0, baseElement);
  },


  showContent: function(contentIndex, baseElement){
    if(!baseElement) baseElement = $('.'+FN.overlay.selectors.baseElementClass);
    $(baseElement).find('.'+FN.overlay.selectors.contentElementClass).each(function(index, element){
      if(index === contentIndex){
        $(element).fadeIn(FN.overlay.config.fadeDuration).removeClass('hidden');
      }else{
        $(element).fadeOut(FN.overlay.config.fadeDuration).addClass('hidden');
      }
    });
  },


  close: function(event){
    var baseElement = $(event.target).closest('.'+FN.overlay.selectors.baseElementClass);
    var contentElements = $(baseElement).find('.'+FN.overlay.selectors.contentElementClass);

    $(contentElements).fadeOut(FN.overlay.config.fadeDuration, function(){
      $(baseElement).removeClass('visible');
    });

    event.preventDefault();
  }
}



/***********
* Usage
*/
/*
var overlay = FN.overlay.init({
  baseElement: '.overlay-wrapper'
});
*/
