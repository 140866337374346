if(typeof FN == 'undefined') FN = {};


FN.announcementBar = {
  config: {
    selectors: {
      'wrapperClass': 'announcement-bar'
    }
  },
  
  
  init: function() {
    var announcementBarEl = $('.'+ FN.announcementBar.config.selectors.wrapperClass);
    if(announcementBarEl.length != 1) return;
    
    $(announcementBarEl).find('.inner-container').addClass('visible');
  }
};




$(window).on('load', FN.announcementBar.init);
