if(typeof FN == 'undefined') FN = {};

FN.contentDownload = {
  selectors: {
    'downloadButtonClass': 'content-download-link',
    'contentDownloadOverlayBaseClass': 'overlay__container--content-download'
  },
  
  
  init: function() {
    $(document).on('click', '.'+FN.contentDownload.selectors.downloadButtonClass, function(event){
      event.preventDefault();
      var clickedElement = this;
      FN.contentDownload.startDownload(clickedElement);
      FN.contentDownload.showPopup();
    });
  },
  
  
  startDownload: function(clickedElement){
    if(!$(clickedElement).is('a')) return;
    
    var url = $(clickedElement).attr('href');
    var iFrame = '<iframe style="display: none !important" src="'+ url +'">';
    $(iFrame).appendTo('body');
  },
  
  
  showPopup: function() {
    var baseElementSelector = '.' + FN.contentDownload.selectors.contentDownloadOverlayBaseClass;
    FN.overlay.init({ baseElement: baseElementSelector });
  }
};




$(document).ready(function(){
  FN.contentDownload.init();
});
