if(typeof FN == 'undefined') FN = {};


FN.educationOffer = {
  config: {
    selectors: {
      'educationPageId': 'page--education',
      'studentsPageId': 'page--students',
      'formId': 'education-offer-form',
      'emailFieldSelector': 'input[name="email"]',
      'requestNoticeSelector': '#education-offer-form__request-notice',
      'formRowClass': 'contact-form__row',
      'honeypotSelector': '#field-hp_consent'
    },
    messages: {
      'progress': 'One moment, please: your request is in progress...',
      'invalidEmail': 'Please provide a valid email address!',
      'generalError': 'The request failed: please try again later or contact support@git-tower.com!',
      'requiredFieldMissing': 'Please fill and check all required fields.',
      'alreadyVerified': 'It seems that this email address has already been approved for this offer.',
      'checkInbox': 'Thank you! Please check your email inbox!',
      'honeypotFilled': 'Please don\'t enter anything into the honeypot field.'
    },
    constants: {
      'enableSubmitButton': 1001,
      'disableSubmitButton': 1002
    },
    formTypes: {
      'student': {
        'showFields':     ['email'],
        'requiredFields': ['email']
      },
      'teacher': {
        'showFields':     ['email', 'info[user_name]', 'info[org_name]', 'info[org_type]', 'info[user_role]', 'info[license_usage]', 'info[notes]', 'legal', 'confirm_education'],
        'requiredFields': ['email', 'info[user_name]', 'info[org_name]', 'info[org_type]', 'info[user_role]', 'info[license_usage]', 'legal', 'confirm_education']
      },
      'school': {
        'showFields':     ['email', 'info[user_name]', 'info[org_name]', 'info[org_type]', 'info[user_role]', 'info[license_users]', 'info[license_seats]', 'info[license_usage]', 'info[notes]', 'legal', 'confirm_education'],
        'requiredFields': ['email', 'info[user_name]', 'info[org_name]', 'info[org_type]', 'info[user_role]', 'info[license_users]', 'info[license_seats]', 'info[license_usage]', 'legal', 'confirm_education']
      }
    }
  },
  
  
  init: function() {
    if($('#' + FN.educationOffer.config.selectors.educationPageId).length !== 1 && $('#' + FN.educationOffer.config.selectors.studentsPageId).length !== 1) return;
    
    $('#' + FN.educationOffer.config.selectors.formId + ' #form-type select').change(function(){
      FN.educationOffer.showFormType();
    });

    $('#' + FN.educationOffer.config.selectors.formId).on('submit', FN.educationOffer.sendEducationOfferRequest);
  },
  

  sendEducationOfferRequest: function(event) {
    event.preventDefault();
    
    var isFormValid = FN.educationOffer.__checkFormValidity();
    if(!isFormValid) return false;
    
    var formElement = $('#' + FN.educationOffer.config.selectors.formId); 

    // notification that request is in progress...
    FN.educationOffer.__showStatus(FN.educationOffer.config.messages.progress, false);
    FN.educationOffer.__toggleSubmitButton(FN.educationOffer.config.constants.disableSubmitButton);

    // ajax request
    $.ajax({
      url: FN.config.urls.educationApi,
      method: 'POST',
      headers: {
        "Accept" : "application/json",
        "Content-Type": "application/json"
      },
      data: $(formElement).serialize(),
      dataType: 'json',
      success: function(response){
        if(response['status'] == 400) {
          FN.educationOffer.__showStatus(FN.educationOffer.config.messages.invalidEmail, 'error');
          FN.educationOffer.__toggleSubmitButton(FN.educationOffer.config.constants.enableSubmitButton);
        }else if(response['status'] > 400) {
          FN.educationOffer.__showStatus(FN.educationOffer.config.messages.generalError, 'error');
          FN.educationOffer.__toggleSubmitButton(FN.educationOffer.config.constants.enbleSubmitButton);
        }else if('data' in response && 'status' in response.data && response.data.status === "verified"){
          FN.educationOffer.__showStatus(FN.educationOffer.config.messages.alreadyVerified, 'success');
          FN.educationOffer.__toggleSubmitButton(FN.educationOffer.config.constants.disableSubmitButton);
        }else{
          FN.educationOffer.__showStatus(FN.educationOffer.config.messages.checkInbox, 'success');
          FN.educationOffer.__toggleSubmitButton(FN.educationOffer.config.constants.disableSubmitButton);
        }
        // log event in Google Analytics
        var offerType = (FN.educationOffer.__getFormTypeId() === 'student') ? 'student' : 'education';
        FN.analytics.sendEventToGA({ 'eventCategory': 'sales', 
                                     'eventAction': offerType + '-offer-signup', 
                                     'eventLabelSuffix': ''});
      },
      fail: function(data){
        FN.educationOffer.__showStatus(FN.educationOffer.config.messages.generalError, 'error');
        FN.educationOffer.__toggleSubmitButton(FN.educationOffer.config.constants.enableSubmitButton);
      }
    });
  },
  
  
  showFormType: function(){
    var formTypeId = FN.educationOffer.__getFormTypeId();
    var formConfig = FN.educationOffer.__getFormTypeConfig();
    if(!formConfig) return;

    // show fields
    $('.' + FN.educationOffer.config.selectors.formRowClass).hide();
    $('#form-type, #form-submit').show();
    for(var f=0; f<formConfig.showFields.length; f++){
      var fieldNameSelector = '#' + FN.educationOffer.config.selectors.formId + ' [name="' + formConfig.showFields[f] + '"]';
      $(fieldNameSelector).closest('.'+FN.educationOffer.config.selectors.formRowClass).show();
    }
    
    // prepare form fields
    if(formTypeId === 'teacher') {
      $('input[name="info[license_seats]"]').val("1");
    }
  },
  

  __getFormTypeId: function() {
    if($('#' + FN.educationOffer.config.selectors.studentsPageId).length === 1){
      var formTypeId = 'student';
    }else{
      var formTypeId = $('#' + FN.educationOffer.config.selectors.formId + ' #form-type select').val();
    }
    return formTypeId;
  },
  
    
  __getFormTypeConfig: function() {
    var formTypeId = FN.educationOffer.__getFormTypeId();
    var formTypeConfig = (formTypeId in FN.educationOffer.config.formTypes) ? FN.educationOffer.config.formTypes[formTypeId] : false;
    return formTypeConfig;
  },
  
  
  __checkFormValidity: function(){
    if (FN.educationOffer.__honeypotFilled()) {
      FN.educationOffer.__showStatus(FN.educationOffer.config.messages.honeypotFilled, 'error');
      return false;
    }

    // every visible field is 'required'
    var formTypeConfig = FN.educationOffer.__getFormTypeConfig();
    if(!formTypeConfig) return false;

    for(var i=0; i<formTypeConfig.requiredFields.length; i++){
      var selector = '#' + FN.educationOffer.config.selectors.formId + " [name='"+ formTypeConfig.requiredFields[i] +"']";
      var field = $(selector);

      if($(field).is('input[type="text"]') || $(field).is('textarea')){
        //----- text input // textarea
        var fieldVal = $(field).val();
        if(fieldVal === ""){
          // a required field is empty!
          FN.educationOffer.__showStatus(FN.educationOffer.config.messages.requiredFieldMissing, 'error');
          return false;
        }
        
        if($(field).is('input[name="email"]')) {
    		  var emailFilter = /^.+@.+\..{2,10}$/;
      		// check email address
      		if (!fieldVal || !(emailFilter.test(fieldVal))) {
    			  FN.educationOffer.__showStatus(FN.educationOffer.config.messages.invalidEmailMessage, 'error');
        		return false;
      		}
        }
        
      }else if($(field).is('input[type="checkbox"]')){
        //----- checkboxes
        var checked = $(field).prop('checked');
        if(!checked) {
          FN.educationOffer.__showStatus(FN.educationOffer.config.messages.requiredFieldMissing, 'error');
          return false;
        }

      }else if($(field).is('select')){
        //----- select fields
        var fieldVal = $(field).val();
        if(!fieldVal){
          // a required field is empty!
          FN.educationOffer.__showStatus(FN.educationOffer.config.messages.requiredFieldMissing, 'error');
          return false;
        }
      }
    }
    return true;
  },


  __honeypotFilled: function () {
    $field = $(FN.educationOffer.config.selectors.honeypotSelector);
    val = $field.val();
    return val !== "";
  },


  __showStatus: function(statusMessage, statusType) {
    var requestNoticeContainer = $(FN.educationOffer.config.selectors.requestNoticeSelector);
    $(requestNoticeContainer).html(statusMessage);
    $(requestNoticeContainer).removeClass('success error');
    if(statusType) $(requestNoticeContainer).addClass(statusType);
  },
  
  
  __toggleSubmitButton: function(onOrOff){
    var disabled = (onOrOff === FN.educationOffer.config.constants.disableSubmitButton) ? true : false;
    $('#' + FN.educationOffer.config.selectors.formId + ' input[type="submit"]').prop('disabled', disabled);
  }
};





$(window).on('load', function(){
  FN.educationOffer.init();
});
