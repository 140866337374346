if (typeof FN == 'undefined') { FN = {}; }


FN.jobApplication = (function () {
  var fields = [
    'name', 'email', 'position', 'remote', 'location', 'language',
    'portfolio', 'person', 'projects', 'like-hate', 'why-tower', 'good-team',
    '10-years', 'anything-else'
  ];
  var labels = {
    'name': 'Your name',
    'email': 'Your email',
    'position': 'Which position are you interested in?',
    'remote': 'Have you worked remotely before?',
    'location': 'Where are you located?',
    'language': 'Which language(s) do you speak?',
    'portfolio': 'Portfolio URL(s)',
    'person': 'Tell us a bit about yourself. Who are you? What are you passionate about?',
    'projects': 'Tell us about some of your recent projects.',
    'like-hate': "What do you like about your job? What don't you like?",
    'why-tower': 'Why are you interested in working at Tower?',
    'good-team': 'What characteristics must a "good" team have, in your opinion? What\'s important for you when working with other people?',
    '10-years': 'Can you imagine working at Tower for the next 10 years? What do you need in order to stay happy?',
    'anything-else': 'Anything else? Any questions for us?'
  };
  var messages = {
    'success': 'Your application has been sent. Thank you for applying!',
    'error': 'Your application could not be sent. Please try again or contact us directly at support@git-tower.com.',
    'honeypotError': 'Please don\'t enter anything into the honeypot field.'
  };
  var selectors = {
    form: '#job-application-form',
    button: 'input[type="submit"]',
    result: '.request-notice',
    honeypot: '#field-hp_consent'
  };
  var elements = {
    form: null,
    button: null,
    result: null,
  };
  var apiURL = FN.config.urls.apiBase + FN.config.urls.apiJobApplication;


  function init() {
    elements.form = document.querySelector(selectors.form);

    if (elements.form) {
      elements.button = elements.form.querySelector(selectors.button);
      elements.result = elements.form.querySelector(selectors.result);
      
      elements.form.addEventListener('submit', submitJobApplication);
    }
  }


  function getInternalMessage() {
    var data = {};
    var message = '';
    var key;

    fields.forEach(function (key) {
      data[key] = elements.form.elements[key].value.trim();
    });

    for (key in data) {
      message += '#### ' + labels[key] + '\n\n' + data[key] + '\n\n';
    }

    return message.trim();
  }


  function getApplicantMessage() {
    var message = "Thank you so much for your interest in joining our team!\n\n"
      + "We are currently collecting all incoming applications before we approach a handful of candidates for an in-depth interview.\n\n"
      + "Here's a copy of what you've sent us:\n\n"
      + "================\n\n"
      + getInternalMessage();

    return message.trim();
  }


  function displaySuccess() {
    elements.result.textContent = messages.success;
    elements.result.classList.remove('error');
    elements.result.classList.add('success');
  }


  function displayError() {
    elements.result.textContent = messages.error;
    elements.result.classList.remove('success');
    elements.result.classList.add('error');
  }


  function displayHoneypotError() {
    elements.result.textContent = messages.honeypotError;
    elements.result.classList.remove('success');
    elements.result.classList.add('error');
  }

  function onRequestLoad() {
    elements.button.disabled = false;

    if (this.status < 400) {
      displaySuccess();

      // Clear form
      fields.forEach(function (key) {
        console.log("clearing field", key);
        console.log(elements.form.elements[key]);
        elements.form.elements[key].value = "";
        console.log(elements.form.elements[key].value);
      });
    }
    else {
      // "Errors" which still result in a status code (like 404s) end up here
      displayError();
    }
  }


  function onRequestError() {
    elements.button.disabled = false;

    displayError();
  }


  function honeypotFilled() {
    $field = $(selectors.honeypot);
    val = $field.val();
    return val !== "";
  }


  function submitJobApplication(event) {
    var data;
    var request;

    event.preventDefault();

    elements.button.disabled = true;

    if (honeypotFilled()) {
      elements.button.disabled = false;
      displayHoneypotError();
      return;
    }

    // By this point, the input types and their "required" attributes have done
    // any necessary front-end validation.
    data = {
      applicantName: elements.form.elements['name'].value,
      applicantEmail: elements.form.elements['email'].value,
      messageInternal: getInternalMessage(),
      messageApplicant: getApplicantMessage()
    };

    request = new XMLHttpRequest();
    request.addEventListener('load', onRequestLoad);
    request.addEventListener('error', onRequestError);
    request.open('POST', apiURL);
    request.setRequestHeader('Content-Type', 'application/json');
    request.send(JSON.stringify(data));
  }


  return {
    init: init
  };
})();


document.addEventListener('DOMContentLoaded', function () {
  FN.jobApplication.init();
});


$(document).ready(function () {
  $('.job-opening__teaser a, .job-opening h3').on('click', function (event) {
    event.preventDefault();
    
    var parentContainer = $(this).closest('.job-opening');
    $(this).hide();
    $(parentContainer).find('.job-opening__details').show();
  });
});
