/******************
* Send and process a remote request (e.g. for newsletter subscription)
*******************/
if(typeof FN == 'undefined') FN = {};

FN.remoteRequest = {
  sendRequest: function(url, requestData, options){
  	$(options.responseContainer)
  		.html('Your request is being submitted...')
  		.removeClass('error')
  		.removeClass('success')
  		.addClass('info');
    
    $.ajax({
      type: (options.httpMethod) ? options.httpMethod : 'POST',
      url: url,
      data: requestData,
      dataType: 'json',
      complete: function(xhr, textStatus) {
        var message;
        if(xhr.status >= 200 && xhr.status < 400){
          message = (xhr.responseJSON && ('message' in xhr.responseJSON)) ? xhr.responseJSON.message : "Your request was successfully sent.";
        }else{
          message = (xhr.responseJSON && ('message' in xhr.responseJSON)) ? xhr.responseJSON.message : "An error occured. Please contact support via email at support@git-tower.com";
        }

  			FN.remoteRequest.showResult(xhr.status, message, options);
        
        if(options.onComplete){
          if (typeof options.onComplete === "function"){ options.onComplete(xhr, options); }
        }
      }
    });
  },
  
  showResult: function(status, message, options){
    var successful = (status >= 200 && status < 400);
    
  	if(successful && options.disableButtonOnSuccess){
      $(options.disableButtonOnSuccess).attr('disabled', 'disabled');
  	}
    if(successful && options.disableButtonOnFailure){
      $(options.disableButtonOnFailure).attr('disabled', 'disabled');
    }
  
    if(options.responseContainer){
      $(options.responseContainer)
  		  .html(message)
        .removeClass('info')
        .addClass((successful ? 'success' : 'error'));
    }
  }
};



/***** Usage example: 

$(document).ready(function(){
  $('.newsletter-form').on('submit', function(event){
    var requestData = $(this).serialize();
    var messageContainer = $(this).find('.request-notice');
    FN.remoteRequest.sendRequest(FN.remoteRequest.urls.newsletter, requestData, {'responseContainer':messageContainer});
    event.preventDefault();
  });
});

*/
