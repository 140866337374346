if(typeof FN === 'undefined'){ var FN = {}; }


FN.videoPlayer = {
  videos: [
    { 'name': 'tower-mac', 'videoId': 'JRSxr-PU0wk', 'ratio':1.7777, 'width':1280, 'isPlaying': false },
    { 'name': 'tower-windows', 'videoId': '9rqsPFdW0gc', 'ratio':1.7777, 'width':1280, 'isPlaying': false }
  ],
  playerMargin: 70,
  selectors: {
    'overlayWrapperClass': 'overlay__container--screencast',
    'playerWrapperClass': 'screencast__wrapper',
    'playerID': 'screencast__player',
    'closeButtonClass': 'overlay__button--close'
  },
  player: false,
  
  init: function(){
    $.getScript("https://www.youtube.com/iframe_api");
    $('.button--play-screencast').on('click', function(event){
      FN.videoPlayer.play('tower-mac', event);
    });
    $('.visible-on--windows .button-big--screencast').on('click', function(event){
      FN.videoPlayer.play('tower-windows', event);
    });
    $('.'+FN.videoPlayer.selectors.overlayWrapperClass + ' .'+FN.videoPlayer.selectors.closeButtonClass).on('click', FN.videoPlayer.close);
  },
  
  play: function(videoName, event){
    event.preventDefault();
    
    FN.videoPlayer._setActiveVideo(videoName);
    FN.videoPlayer._showPlayer();
    FN.videoPlayer._initVideo();
  },
  
  close: function(){
    $('#'+FN.videoPlayer.selectors.playerID).html('');
    $('.'+FN.videoPlayer.selectors.overlayWrapperClass).fadeOut(0.1);
    $(document).off('keyup.screencast-closer');
    FN.videoPlayer.player.stopVideo();
    FN.videoPlayer.player.clearVideo();
    $('#'+FN.videoPlayer.selectors.playerID).remove();
    return false;
  },
  
  _showPlayer: function(){
    var activeVideo = FN.videoPlayer._getActiveVideo();
    var width = ($(window).width() > activeVideo.width) ? activeVideo.width : $(window).width() - (2*FN.videoPlayer.playerMargin);
    var height = width / activeVideo.ratio;
    if(height > $(window).height() - (2*FN.videoPlayer.playerMargin)){
      height = $(window).height() - (2*FN.videoPlayer.playerMargin);
      width = height * activeVideo.ratio;
    }
    
    $('.'+FN.videoPlayer.selectors.playerWrapperClass).append('<div id="screencast__player"></div>');
    $('#'+FN.videoPlayer.selectors.playerID).css({'width': width, 'height': height});
		$('.'+FN.videoPlayer.selectors.overlayWrapperClass).addClass('visible').fadeIn(0.1);
    
    $(document).on('keyup.screencast-closer', function(e){
      if(e.keyCode === 27){
        FN.videoPlayer.close();
      }
    });
  },
  
  _setActiveVideo: function(videoName){
    for(var i=0; i<FN.videoPlayer.videos.length; i++){
      if(videoName === FN.videoPlayer.videos[i].name){
        FN.videoPlayer.videos[i].isPlaying = true;
      }else{
        FN.videoPlayer.videos[i].isPlaying = false;
      }
    }
  },
  
  _getActiveVideo: function(){
    for(var i=0; i<FN.videoPlayer.videos.length; i++){
      if(FN.videoPlayer.videos[i].isPlaying == true){
        return FN.videoPlayer.videos[i];
      }
    }
    return false;
  },
  
  _initVideo: function(){
    var activeVideo = FN.videoPlayer._getActiveVideo();
    FN.videoPlayer.player = new YT.Player(FN.videoPlayer.selectors.playerID, {
      videoId: activeVideo.videoId,
      playerVars: {
          autohide: 1,            // Values: 2 (default), 1, and 0. This parameter indicates whether the video controls will automatically hide after a video begins playing
          autoplay: 1,            // Values: 0 or 1. Default is 0. Sets whether or not the initial video will autoplay when the player loads.
          controls: 1,            // Values: 0 (do not display), 1, or 2. Default is 1. This parameter indicates whether the video player controls will display
          enablejsapi: 0,         // Values: 0 or 1. Default is 0. Setting this to 1 will enable the Javascript API
          fs: 1,                  // Values: 0 or 1. The default value is 1, which causes the fullscreen button to display. Setting this parameter to 0 prevents the fullscreen button from displaying
          hl: 'en',               // Sets the player's interface language
          iv_load_policy: 3,      // Values: 1 or 3. Default is 1. Setting to 1 will cause video annotations to be shown by default, whereas setting to 3 will cause video annotations to not be shown by default
          modestbranding: 1,      // This parameter lets you use a YouTube player that does not show a YouTube logo. Set the parameter value to 1 to prevent the YouTube logo from displaying in the control bar
          origin: '',             // This parameter provides an extra security measure for the IFrame API and is only supported for IFrame embeds. If you are using the IFrame API, which means you are setting the enablejsapi parameter value to 1, you should always specify your domain as the origin parameter value
          playsinline: 0,         // This parameter controls whether videos play inline or fullscreen in an HTML5 player on iOS
          rel: 0,                 // Values: 0 or 1. Default is 1. This parameter indicates whether the player should show related videos when playback of the initial video ends
          showinfo: 0,            // Values: 0 or 1. The parameter's default value is 1. If you set the parameter value to 0, then the player will not display information like the video title and uploader before the video starts playing
          theme: 'dark'           // This parameter indicates whether the embedded player will display player controls (like a play button or volume control) within a dark or light control bar. Valid parameter values are dark and light
                // full reference: https://developers.google.com/youtube/player_parameters.html?playerVersion=HTML5
      },
      events: {
        'onReady': function(event){ event.target.playVideo(); }
      }
    });
  }
};
