if(typeof FN == 'undefined') FN = {};


FN.testimonials = {
  config: {
    selectors: {
      'testimonialPlaceholderClass': 'testimonial-placeholder'
    }
  },
  
  init: function() {
    if(typeof FN.testimonialData == 'undefined') return;
    
    // replace testimonial placeholders with real data
    $('.' + FN.testimonials.config.selectors.testimonialPlaceholderClass).each(function(index, item){
      var testimonialId = $(item).data('id');
      var testimonialData = FN.testimonials.findTestimonialDataById(testimonialId);
      if(!testimonialData) return;
      
      var testimonialLayout = $(item).data('layout')
      if(!testimonialLayout) testimonialLayout = 'mini';
      var testimonialHtml = FN.testimonials.buildHtml(testimonialData, testimonialLayout);
      $(item).replaceWith(testimonialHtml);
    });
  },
  
  findTestimonialDataById: function(id) {
    var testimonialData = false;
    for(var i=0; i<FN.testimonialData.length; i++){
      if(id == FN.testimonialData[i]['id']){
        testimonialData = FN.testimonialData[i];
        break;
      }
    }
    return testimonialData;
  },
  
  buildHtml: function(testimonial, layout) {
    var html = '<div class="testimonial testimonial--'+ layout +'">';
    html    += '  <div class="testimonial__image"><img src="'+ FN.helpers.absoluteUrl(testimonial['image']) + '@140w.jpg" width="140" height="140" alt="'+ testimonial['name']+ '" /></div>';
    html    += '  <p class="testimonial__text">'+ testimonial['text'] +'</p>';
    html    += '  <p class="testimonial__person">'
    
    if (testimonial['link'] !== '') {
      html  += '<a href=' + testimonial['link'] + ' target="_blank" rel="noopener noreferrer"><span class="testimonial__name">'+ testimonial['name'] +'</span></a>'
    } else {
       html  += '<span class="testimonial__name">'+ testimonial['name'] +'</span>'
    }

    if (testimonial['company'] !== '') {
      html    += '<span class="testimonial__company">'+ testimonial['company'] +'</span>';
    }
    
    html    += '</p></div>';

    return html;
  }
};




$(document).ready(function(){
  FN.testimonials.init();
});
