if(typeof FN == 'undefined') FN = {};


FN.releaseNotesConfig = {
  selectors: {
    naviLinksSelector: '.navi a',
    naviLinkActiveClass: 'navi--active',
    naviLinkHighlightClass: 'navi--highlights',
    naviLinkReleaseNotesClass: 'navi--release-notes',
    contentHighlightsClass: 'release__highlights',
    contentReleaseNotesClass: 'release__notes',
    majorReleaseContainerClass: 'release--major',
    checksumButtonSelector: '.release__copy-checksum'
  }
}




FN.releaseNotes = {
  init: function() {
    if($('#page--release-notes').length != 1) return;
    
    FN.releaseNotes.autoplayVideos();
    FN.releaseNotes.autoloadAnimatedGifs();
    FN.releaseNotes.initNavi();
    FN.releaseNotes.initChecksumButtons();
  },

  autoplayVideos: function() {
    FN.videoAutoplayer.init();
  },
  
  autoloadAnimatedGifs: function() {
    FN.releaseNotes.loadAnimatedGifs();
    // scroll event handler
    $(window).on('scroll', FN.releaseNotes.loadAnimatedGifs);
  },
  
  loadAnimatedGifs: function() {
    $('img[data-preview="true"]').each(function(i, item){
      // is the image currently visible on the page / viewport?
      var visibleInViewport = FN.helpers.isVisibleInViewport({element: item, minimumVisiblePercent:40});
      // is the image currently visible by CSS properties (note that we have to check the _parent_ element)
      var visibleInCSS = $(item).parent().css(['opacity', 'visibility', 'display']);

      if(visibleInViewport && visibleInCSS.opacity > 0 && visibleInCSS.visibility != 'hidden' && visibleInCSS.display != 'none'){
        var newSource = $(item).data('src');
        $(item).attr('src', newSource);
      }
    });
  },
  
  initNavi: function() {
    $(FN.releaseNotesConfig.selectors.naviLinksSelector).on('click', function(event){
      var parentContainer = $(this).closest('.' + FN.releaseNotesConfig.selectors.majorReleaseContainerClass);

      if($(this).hasClass(FN.releaseNotesConfig.selectors.naviLinkHighlightClass)){
        // show highlights
        $('.'+FN.releaseNotesConfig.selectors.contentReleaseNotesClass).hide();
        $('.'+FN.releaseNotesConfig.selectors.contentHighlightsClass).show();

        // Set active state for "highlights" links
        $(FN.releaseNotesConfig.selectors.naviLinksSelector).removeClass(FN.releaseNotesConfig.selectors.naviLinkActiveClass);
        $('.' + FN.releaseNotesConfig.selectors.naviLinkHighlightClass).addClass(FN.releaseNotesConfig.selectors.naviLinkActiveClass);
      }else{
        // show release notes
        $('.'+FN.releaseNotesConfig.selectors.contentHighlightsClass).hide();
        $('.'+FN.releaseNotesConfig.selectors.contentReleaseNotesClass).show();

        // Set active state for "release notes" links
        $(FN.releaseNotesConfig.selectors.naviLinksSelector).removeClass(FN.releaseNotesConfig.selectors.naviLinkActiveClass);
        $('.' + FN.releaseNotesConfig.selectors.naviLinkReleaseNotesClass).addClass(FN.releaseNotesConfig.selectors.naviLinkActiveClass);
      }
    });
  },

  initChecksumButtons: function () {
    $(FN.releaseNotesConfig.selectors.checksumButtonSelector).on('click', function (event) {
      event.preventDefault();

      var checksum = $(event.target).data('checksum');

      // To copy the checksum to the clipboard, we create a temporary,
      // non-visible input element with the checksum as its value, add it to the
      // DOM, select its contents, and execute the "copy" command. The temporary
      // element is then removed from the DOM.
      var tmpEl = $('<input>');
      tmpEl.css({ position: 'absolute', left: '-1000%', opacity: '0' });
      tmpEl.val(checksum);
      $('body').append(tmpEl);
      tmpEl[0].select();

      document.execCommand("copy")

      tmpEl.remove();
      $(event.target).text($(event.target).text().replace('Copy', 'Copied'));
    });
  }
};




$(document).ready(function(){
  FN.releaseNotes.init();
});
