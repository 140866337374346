if(typeof FN == 'undefined') FN = {};


FN.contentTextWithContent = function(itemConfig){
  /****************
  * Initialization
  */
  this.__init = function(itemConfig){
    this.platform = (typeof FN.osSwitch == 'undefined' || !FN.osSwitch.currentPlatform) ? FN.helpers.platform() : FN.osSwitch.currentPlatform;
    this.itemConfig = itemConfig;

    // build + insert HTML
    this.__buildItemHtml();
    
    // find + save DOM elements
    this.baseElement = $('#' + this.itemConfig['id']);
    
    // event handlers
    $('video', this.baseElement).on('loadeddata', $.proxy(this.videoHasLoadedData, this));
  };
  
  
  /*****************
  * Build HTML structure of item
  */
  this.__buildItemHtml = function(){
    var html = '<div class="inner-container inner-container--maxwidth">';
    var contentConfig = this.__contentConfig();
    
    for(var key in contentConfig){
      if(key === 'text'){
        html += this.__buildItemHtmlForText(contentConfig['text']);
      }else if(key === 'content') {
        html += this.__buildItemHtmlForContent(contentConfig['content']);
      }
    }
    html += '</div>';
    
    $('#' + this.itemConfig['id']).html(html);
  };
  
  
  this.__buildItemHtmlForText = function(contentItem) {
    var html = '<div class="text-with-content__text">';
    if(contentItem['headline']) {
      html += '<h2 class="font--white">' + FN.helpers.absoluteUrl(contentItem['headline']) + '</h2>';
    }
    if(contentItem['subline']) {
      html += '<p>' + FN.helpers.absoluteUrl(contentItem['subline']) + '</p>';
    }
    html += '</div>';
    
    return html;
  };
  
  
  this.__buildItemHtmlForContent = function(contentItem) {
    var contentItemSource = this.__bestItemSourceForCurrentScreen(contentItem);
    var itemClass = 'text-with-content__content text-with-content__content--'+ contentItemSource['type'];
    if(contentItemSource['class']) itemClass += ' text-with-content__content--' + contentItemSource['class'];
    
    var html = '<div class="'+ itemClass +'" data-source-media="'+ contentItemSource['media'] +'">';
    if(contentItemSource['type'] === 'video'){
      html += this.__buildItemHtmlForVideo(contentItemSource);
    }else if(contentItemSource['type'] === 'picture'){
      html += this.__buildItemHtmlForPicture(contentItemSource);
    }
    html += '</div>';
    
    return html;
  };
  
  
  this.__buildItemHtmlForVideo = function(contentItemSource){
    html = '<video preload="metadata" playsinline="playsinline" loop="loop" muted="muted" class="loading">';

    // main video source
    if('src' in contentItemSource) {
      var mainSourceUrl = FN.helpers.absoluteUrl(contentItemSource['src']);
      html += '<source src="'+ mainSourceUrl +'" type="video/mp4">';
    }
    // fallback sources
    if('fallback' in contentItemSource) {
      for(var f=0; f<contentItemSource['fallback'].length; f++) {
        var fallbackUrl = FN.helpers.absoluteUrl(contentItemSource['fallback'][f]);
        if(contentItemSource['fallback'][f].match(".mp4$")){
          html += '<source src="'+ fallbackUrl +'" type="video/mp4">';
        }else{
          html += '<img src="'+ fallbackUrl +'" />'
        }
      }
    }

    html += '</video>';

    return html;
  };


  this.__buildItemHtmlForPicture = function(contentItemSource){
    var srcSet = ('srcset' in contentItemSource) ? FN.helpers.absoluteUrl(contentItemSource['srcset']) : '';
    var imgSrc = ('src' in contentItemSource) ? FN.helpers.absoluteUrl(contentItemSource['src']) : '';
    
    html = '<picture>';
    html += ' <source srcset="'+ srcSet +'" />';
    html += ' <img src="'+ imgSrc +'" />';
    html += '</picture>';
    
    return html;
  };
  
  
  this.__contentConfig = function() {
    // find the corresponding content config - depending on the OS / platform and the fact if "content_all" is defined
    if('content_all' in this.itemConfig) {
      return this.itemConfig['content_all'];
    }else if('content_'+this.platform in this.itemConfig){
      return this.itemConfig['content_'+this.platform];
    }else{
      return false;
    }
  };
  
  
  /*****************
  * From the sources defined for this item, find the one that matches the current
  * screen / breakpoint best
  */
  this.__bestItemSourceForCurrentScreen = function(contentItemMedia) {
    for(var s=0; s<contentItemMedia.length; s++){
      var breakpoint = FN.helpers.getResponsiveBreakpointByName(contentItemMedia[s]['media']);
      if (window.matchMedia('(min-width: '+ breakpoint['width'] +')').matches) {
        return contentItemMedia[s];
      }
    }
    return false;
  };
  
  
  /*****************
  * Video has loaded
  */
  this.videoHasLoadedData = function(event){
    // display the video
    var videoElement = event.target;
    $(videoElement).removeClass('loading').addClass('ready');
  };
  
  
  /*****************
  * Check if we need to re-init a media item because a different breakpoint size
  * would be more suitable (possibly the case after the window was resized)
  */
  this.__checkContentItemSizes = function(event) {
    var contentConfig = this.__contentConfig();
    if(!contentConfig) return false;
    
    var currentItemSource = $(this.baseElement).find('.'+FN.contentTextWithContent.static.selectors.contentItemClass).data('source-media');
    var bestItemSourceWithCurrentScreenSize = this.__bestItemSourceForCurrentScreen(contentConfig['content']);

    if(currentItemSource && bestItemSourceWithCurrentScreenSize && (currentItemSource != bestItemSourceWithCurrentScreenSize['media'])) {
      // re-init, in order to replace with more appropriate source
      this.__init(this.itemConfig);
      return;
    }
  };
  
  
  /*****************
  * Constructor
  */
  this.__init(itemConfig);
}



/**************
* Static Variables
*/
FN.contentTextWithContent.static = {
  itemInstances: [],
  selectors: {
    itemWrapperClass:  'text-with-content',
    contentItemClass: 'text-with-content__content',
    textItemClass: 'text-with-content__text'
  },
  
  initAllItems: function(){
    if('contentTextWithContentItems' in FN){
      for(var i=0; i<FN.contentTextWithContentItems.length; i++){
        var itemConfig = FN.contentTextWithContentItems[i];
        FN.contentTextWithContent.static.itemInstances.push(new FN.contentTextWithContent(itemConfig));
      }
    }
    
    // start playing videos that might be visible already now, on pageLoad
    if(typeof FN.videoAutoplayer != 'undefined') FN.videoAutoplayer.playOrPauseAllVideos();
    
    // replace testimonial placeholders
    if(typeof FN.testimonials != 'undefined') FN.testimonials.init();
    
    // react on window resize
    $(window).on('resize', FN.contentTextWithContent.static.windowWasResized);
  },
  
  windowWasResized: function(event){
    for(var i=0; i<FN.contentTextWithContent.static.itemInstances.length; i++){
      var contentTextWithContentItem = FN.contentTextWithContent.static.itemInstances[i]
      contentTextWithContentItem.__checkContentItemSizes();
    }
    if(typeof FN.testimonials != 'undefined') FN.testimonials.init();
  }
}
