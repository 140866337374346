/******************
*
* Newsletter signup management
*
* Documentation: see `newsletter.README.md`
*
*/

if (typeof FN == 'undefined') FN = {};


FN.newsletterConfig = {
  instances: [],
  messages: {
    pleaseProvideEmailAddress: "Please provide a valid email address.",
    pleaseAcceptPrivacyPolicy: "Please read and accept our Privacy Policy.",
    pleaseAcceptTerms: "Please read and accept our terms.",
    pleaseCheckRequiredTags: "If you want to hear from us, please select the options above."
  },
  selectors: {
    formClass: 'newsletter-form',
    emailInput: 'input[name="email"]',
    tagItemClass: 'signup-tag__item',
    consentItemClass: 'consent__item',
    consentTextClass: 'consent__text',
    subscriptionProfileFormID: 'newsletter-form--profile'
  },
  formStatus: {
    error: 'error',
    loading: 'loading',
    success: 'success'
  }
}


FN.newsletter = function (element) {
  /****************
  * Initialization
  */
  this.__init = function (element) {
    this.baseElement = element;

    this.startData = $(this.baseElement).serializeArray();
    $(this.baseElement).on('submit', $.proxy(this.submit, this));
  };


  /**
  * Submit the form data
  */
  this.submit = function (event) {
    event.preventDefault();

    var formStatus = this.validateForm();
    if (formStatus.status === FN.newsletterConfig.formStatus.error) {
      this.displayFormStatus(formStatus);
      return false;
    }

    var requestURL = this.prepareRequestURL();
    var requestData = this.prepareFormData();
    var requestOptions = this.prepareRequestOptions();

    $(this.baseElement).find('input[type="submit"]').attr('disabled', 'disabled');

    FN.remoteRequest.sendRequest(requestURL, requestData, requestOptions);
  };


  /**
  * Validate the form before submitting
  */
  this.validateForm = function () {
    var formStatus = { 'status': false, 'messages': [] };

    // check email
    if (!$(this.baseElement).find(FN.newsletterConfig.selectors.emailInput).val().match(/^.+@.+\..+/g)) {
      formStatus.messages.push({ 'message': FN.newsletterConfig.messages.pleaseProvideEmailAddress });
    }

    // check that tags are selected
    var tagItems = $(this.baseElement).find('.' + FN.newsletterConfig.selectors.tagItemClass);
    for (var i = 0; i < tagItems.length; i++) {
      var requiredTag = (typeof $(tagItems[i]).data('required') != 'undefined') ? $(tagItems[i]).data('required') : false;
      if (requiredTag && !$(tagItems[i]).prop('checked')) {
        formStatus.messages.push({ 'message': FN.newsletterConfig.messages.pleaseCheckRequiredTags });
      }
    }

    // check consent / privacy policy
    var consentItems = $(this.baseElement).find('.' + FN.newsletterConfig.selectors.consentItemClass);
    for (var i = 0; i < consentItems.length; i++) {
      var requiredConsent = $(consentItems[i]).data('required');
      if (requiredConsent && !$(consentItems[i]).prop('checked')) {
        if ($(consentItems[i]).attr('id') === 'consent--privacy') {
          formStatus.messages.push({ 'message': FN.newsletterConfig.messages.pleaseAcceptPrivacyPolicy });
        } else {
          formStatus.messages.push({ 'message': FN.newsletterConfig.messages.pleaseAcceptTerms });
        }
      }
    }

    if (formStatus.messages.length == 0) {
      formStatus.status = FN.newsletterConfig.formStatus.success;
    } else {
      formStatus.status = FN.newsletterConfig.formStatus.error;
    }

    return formStatus;
  };


  /**
  * Collect and prepare form data
  */
  this.prepareFormData = function () {
    var formData = $(this.baseElement).serializeArray();

    // go through all tag items (checkboxes) and find those that are NOW UNSELECTED
    $(this.baseElement).find('.' + FN.newsletterConfig.selectors.tagItemClass).each(function (index, element) {
      var wasCheckedAtStart = ($(element).attr('data-start-value') == 1);
      var isCheckedNow = $(element).is(':checked');
      if (wasCheckedAtStart && !isCheckedNow) {
        var tagName = $(element).attr('data-tag-name');
        formData.push({ name: 'removeTags[]', value: tagName });
      }
    });

    // get consent text + date
    var consentText = '';
    $(this.baseElement).find('.' + FN.newsletterConfig.selectors.consentTextClass).each(function (index, element) {
      if ($(element).prop('checked')) {
        if (consentText !== '') consentText += ' ';
        consentText += $(element).closest('label').text();
      }
    });
    if (consentText) {
      formData.push({ name: 'consent[date]', value: (new Date()).toUTCString() });
      formData.push({ name: 'consent[text]', value: consentText });
    }

    return formData;
  };


  /**
  * Configure request options
  */
  this.prepareRequestOptions = function () {
    var messageContainer = $(this.baseElement).find('.request-notice');
    var requestOptions = {
      'responseContainer': messageContainer,
      'onComplete': $.proxy(this.handleFormResponse, this),
      'redirectOnSuccess': false
    };

    return requestOptions;
  };


  /**
  * Choose the correct request URL
  */
  this.prepareRequestURL = function () {
    var requestURL = FN.config.urls.apiBase;

    var subscriptionStatus = $(this.baseElement).attr('data-subscription-status');
    if (subscriptionStatus === 'subscribed') {
      requestURL += FN.config.urls.apiContactUpdate;
    } else {
      requestURL += FN.config.urls.apiContactSubscribe;
    }

    return requestURL;
  };


  /**
  * Display the status of the form
  */
  this.displayFormStatus = function (formStatus) {
    var messageContainer = $(this.baseElement).find('.request-notice');
    var messageHtml = '';
    for (var i = 0; i < formStatus.messages.length; i++) {
      messageHtml += '<p>' + formStatus.messages[i].message + '</p>';
    }

    $(messageContainer).html(messageHtml).removeClass('success error loading').addClass(formStatus.status);
    if (formStatus.messages.length == 0) {
      $(messageContainer).hide();
    } else {
      $(messageContainer).show();
    }
  };


  /**
  * Handle the request response
  */
  this.handleFormResponse = function (xhr, options) {
    $(this.baseElement).find('input[type="submit"]').removeAttr('disabled');

    if (xhr.status < 400) {
      // trigger a custom jQuery event named "FN.newsletter:success", which other code can listen to:
      // $(document).on("FN.newsletter:success", function(event, context){ ...
      $(document).trigger("FN.newsletter:success", { 'responseData': xhr, 'formElement': $(this.baseElement), 'options': options });

      // Create and dispatch native event for non-jQuery listeners
      var event = new CustomEvent(
        "TowerNewsletterSubscribed",
        {
          "detail": {
            "responseData": xhr,
            "formElement": $(this.baseElement),
            "options": options
          }
        }
      );

      document.dispatchEvent(event);
    }
  };


  /*****************
  * Constructor
  */
  this.__init(element);
};
