/******************
* Create and insert a little Tooltip / Popover window
*
*
* Documentation
*     See the repository's Wiki on GitHub.com:
*     https://github.com/fournova/kirby-toolbox/wiki/javascript:-tooltips.js
*
*/

if(typeof FN == 'undefined') FN = {};

FN.tooltips = {
  config: {
    dimensions: {
      pointerYDistance: 2,
      pointerHeightPixels: 14,
      pointerWidthPixels: 32,
      pointerHorizontalInsetPixels: 6,
      boxBorderWidth: 1
    },
    selectors: {
      tooltipIndicatorClass: 'tooltip-indicator',
      tooltipId: 'tooltip',
      tooltipContentDataAttr: "data-content",
      tooltipPositionDataAttr: "data-pointer-position"
    }
  },
  

  /**
    Init
  */
  init: function(){
    $('.'+ FN.tooltips.config.selectors.tooltipIndicatorClass).on('mouseenter', function(){
      FN.tooltips.showTooltip(this);
    });
    $('.'+ FN.tooltips.config.selectors.tooltipIndicatorClass).on('mouseleave', function(){
      FN.tooltips.hideTooltips();
    });

  },


  /**
    Create and show a tooltip
  */
  showTooltip: function(indicatorItem){
    FN.tooltips.hideTooltips();     // remove all existing tooltips (just to be sure...)

    var tooltipText = $(indicatorItem).attr(FN.tooltips.config.selectors.tooltipContentDataAttr);
    var tooltipPointerPosition = $(indicatorItem).attr(FN.tooltips.config.selectors.tooltipPositionDataAttr).split('-');
    tooltipPointerPosition = { y: tooltipPointerPosition[0], x: tooltipPointerPosition[1] };

    var html = '<div id="'+ FN.tooltips.config.selectors.tooltipId +'">';
    html    += '<div class="tooltip__pointer tooltip__pointer--'+ tooltipPointerPosition.x +' tooltip__pointer--'+ tooltipPointerPosition.y +'"></div>';
    html    += '<div class="tooltip__text">'+ tooltipText +'</div>';
    html    += '</div>';

    $(indicatorItem).append(html);

    var indicatorItemWidth = $(indicatorItem).width();
    var indicatorItemHeight = $(indicatorItem).height();
    var tooltipHeight = $('#'+FN.tooltips.config.selectors.tooltipId).height();
    var tooltipWidth = $('#'+FN.tooltips.config.selectors.tooltipId).width() + (2*FN.tooltips.config.dimensions.boxBorderWidth);

    var tooltipPosition = {};
    if(tooltipPointerPosition.x === 'left') {
      tooltipPosition.left = (indicatorItemWidth/2 - FN.tooltips.config.dimensions.pointerWidthPixels/2 - FN.tooltips.config.dimensions.pointerHorizontalInsetPixels);
    }else if(tooltipPointerPosition.x === 'right') {
      tooltipPosition.right = (indicatorItemWidth/2 - FN.tooltips.config.dimensions.pointerWidthPixels/2 - FN.tooltips.config.dimensions.pointerHorizontalInsetPixels);
    }else{    // 'center' and default
      tooltipPosition.left = (indicatorItemWidth/2 - tooltipWidth/2);
    }

    if(tooltipPointerPosition.y === 'top') {
      tooltipPosition.top = (indicatorItemHeight + FN.tooltips.config.dimensions.pointerHeightPixels + FN.tooltips.config.dimensions.pointerYDistance);
    }else{    // 'bottom' and default
      tooltipPosition.bottom = (indicatorItemHeight + FN.tooltips.config.dimensions.pointerHeightPixels + FN.tooltips.config.dimensions.pointerYDistance);
    }

    var cssAttr = {};
    if('left' in tooltipPosition) cssAttr['left'] = tooltipPosition.left + 'px';
    if('right' in tooltipPosition) cssAttr['right'] = tooltipPosition.right + 'px';
    if('top' in tooltipPosition) cssAttr['top'] = tooltipPosition.top + 'px';
    if('bottom' in tooltipPosition) cssAttr['bottom'] = tooltipPosition.bottom + 'px';

    $('#'+FN.tooltips.config.selectors.tooltipId).css(cssAttr).show();
  },


  /**
    Hide all tooltips
  */
  hideTooltips: function(){
    $('#'+FN.tooltips.config.selectors.tooltipId).remove();
  }  
};




// load configured tooltips - but only once the page is completely loaded
$( window ).on('load', function(){
  FN.tooltips.init();
});
